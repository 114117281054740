<template>
	<div class="ys-login-container" :style="this.getLoginContainerStyle()">
		<div class="ys-login-page-title-container">
			<div class="ys-login-page-title-text">WW社区新零售</div>
			<div class="ys-login-page-sub-title-text">每天五分钟 轻松赚日薪</div>
		</div>
		<div class="ys-login-input-container">
			<img src="../assets/login_icon.png" alt="logo" class="ys-login-container-logo">
			<div class="ys-login-label">登录</div>
			<a-input class="ys-login-input" v-model:value="phoneNum" placeholder="手机号" type="text" size="large">
				<template #prefix>
					<img src="../assets/phone.svg" alt="phone">
				</template>
			</a-input>
			<a-input class="ys-login-input" v-model:value="password" placeholder="登录密码" type="password" size="large">
				<template #prefix>
					<img src="../assets/password.svg" alt="phone">
				</template>
			</a-input>
			<a-input class="ys-login-input" v-model:value="shopCode" placeholder="店号" type="text" size="large">
				<template #prefix>
					<img src="../assets/shop.svg" alt="phone">
				</template>
			</a-input>
			<a-button type="primary" class="ys-login-btn" @click="onLogin" size="large">登录</a-button>
			<div class="ys-login-btn-container">
				<div class="ys-login-user-register" @click="registerUser">新用户注册</div>
				<div class="ys-login-user-register" @click="forgetPassword">忘记密码</div>
			</div>


		</div>
		<div class="ys-login-page-bottom-text">
			一个只谈赚日薪的副业平台
		</div>
	</div>
</template>

<script>
import {userLogin, isValidChineseMobile} from "@/utils/methods";
import { message } from 'ant-design-vue';
export default {
	name: "LoginPage",
	data() {
		return {
			phoneNum: '',
			password: '',
			shopCode: ''
		};
	},
	methods: {
		getLoginContainerStyle() {
			return {
				height: window.innerHeight + 'px'
			}
		},
		async onLogin() {
			if (!this.phoneNum) {
				message.error('手机号不能为空');
				return
			}
			if (!this.password) {
				message.error('密码不能为空');
				return
			}
			if(!this.shopCode) {
				message.error('店号不能为空')
				return
			}
			if (!isValidChineseMobile(this.phoneNum)) {
				message.error('手机号码格式不正确');
				return
			}
			const result = await userLogin(this.phoneNum, this.password, this.shopCode)

			if (result?.code === 200) {
				const sessionId = result?.msg?.sessionId
				const sessionExpireTimeStamp = result?.msg?.sessionExpireTimeStamp

				localStorage.setItem('sessionId', sessionId)
				localStorage.setItem('sessionExpireTimeStamp', sessionExpireTimeStamp)
				message.success('登录成功');
				this.$router.push('/main')
				return
			}

			if (result?.code === 201) {
				message.error('用户不存在')
				return
			}
			if (result?.code === 203) {
				message.error('商户号不正确')
				return
			}
			if (result?.code === 204) {
				this.$router.push({ name: 'resetPassword', params: { phoneNum: this.phoneNum , shopCode: this.shopCode} })
				return
			}

			if (result?.code === 202) {
				message.error('手机号和密码不匹配')
				return
			}

		},
		async forgetPassword() {

			this.$router.push('/resetPassword')
		},
		registerUser () {
			this.$router.push('/register')
		}
	}
}
</script>

<style scoped>

@media (min-width: 900px) {
	.ys-login-container-logo{
		width: 28em !important;
	}

	.ys-login-input-container{
		width: 50em !important;
	}

}
.ys-login-container {
	width: 100%;
	height: 100%;;
	background-color: #7D0C1E;
	position: relative;
	top: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ys-login-page-title-container {
	font-size: 1em;
	color: #E9CB97;
	display: flex;
	flex-direction: column;
	align-items: start;
	width: 100%;
	padding: 1em;
	box-sizing: border-box;
	//position: absolute;
	//top: 1em;
	//left: 1em;
}

.ys-login-page-title-text {
	font-size: 1.6em;
	font-weight: bold;
}

.ys-login-page-sub-title-text {
	font-size: 0.8em;
}

.ys-login-page-bottom-text {
	width: 100%;
	color: #E5C7A1;
	font-size: 1em;
	text-align: center;
	padding: 0.4em;

}

.ys-login-input-container {
	display: flex;
	flex: 1;
	flex-direction: column;
	width: 90%;
	//top: 50%;
	//left: 50%;
	//transform: translate(-50%, -50%);
	align-items: center;
	justify-content: center;
}

.ys-login-input {
	margin-bottom: 1em;
}

.ys-login-label {
	color: #E5C7A1;
	font-size: 1.4em;
	margin-bottom: 1em;
}

.ys-login-btn {
	background-color: #E5C7A1;
	width: 100%;
	margin-bottom: 1em;
}
.ys-login-btn:hover {
	background-color: #b49366;
}

.ys-login-container-logo{
	width: 20em;
}

.ys-login-forget-password{
	color: #5e5e5e;
	cursor: pointer;
}

.ys-login-user-register{
	color: #E5C7A1;
	cursor: pointer;
	margin-bottom: 1em;
	text-decoration: underline;
	padding: 0 1em;
}

.ys-login-btn-container{
	display: flex;
	flex-direction: row;
}

</style>