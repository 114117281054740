<template>
	<div class="ys-my-order-container">

		<div class="ys-my-order-tab-container">
			<div v-for="item in this.myOrders" :key="item.orderId" class="ys-my-order-container-row-wrapper">
				<div class="ys-my-order-cell-header" :style=this.getOrderTypeDisplayStyle(item)>
					<div>{{this.getOrderStatusText(item)}}</div>
					<div>{{ this.getOrderTypeDisplay(item) }}</div>
				</div>
				<div class="ys-my-order-container-row">
					<div class="ys-my-order-image-container">
						<img class="ys-my-order-image" :src=getProductImageUrl(item) alt="商品图片">
					</div>
					<div class="ys-my-order-text-container">
						<div class="ys-my-order-text-cell">
							<div class="ys-my-order-text-cell-content" style="font-weight: bold;">{{
									item.productCode
								}}
							</div>
						</div>
						<div class="ys-my-order-text-cell">
							<div class="ys-my-order-text-cell-content">卖家：{{ item.sellerUserName }}</div>
						</div>
						<div class="ys-my-order-text-cell">
							<div class="ys-my-order-text-cell-content">买家：{{ item.buyerUserName }}</div>
						</div>
						<div class="ys-my-order-text-cell">
							<div class="ys-my-order-text-cell-content">
								交易价格：{{ item.amount }}
							</div>
						</div>
						<div class="ys-my-order-text-cell">
							<div class="ys-my-order-text-cell-content">
								上架费：{{ this.getOnSalePrice(item) }}
							</div>
						</div>
						<!--						<div class="ys-my-order-text-cell">-->
						<!--							<div class="ys-my-order-text-cell-content">-->
						<!--								上架费：{{ this.getOnSalePrice(item)}}-->
						<!--							</div>-->
						<!--						</div>-->
						<div class="ys-my-order-text-cell">
							<div class="ys-my-order-text-cell-content">
								创建时间：{{ item.createTimeString }}
							</div>
						</div>
					</div>
				</div>

				<div class="ys-my-order-display-payment-code-btn" v-if="this.canShowPaymentCode(item)" @click="this.onShowPaymentCodeClick(item)">
					<div>{{this.getShowPaymentCodeBtnText(item)}}</div>
					<img v-if="this.canShowViewIcon(item)" class="ys-my-order-display-payment-code-btn-img" :src="this.getShowPaymentCodeBtnIcon(item)" alt="查看">
				</div>
				<div v-if="item.showPaymentCode === true">
					<a-tabs v-model:activeKey="paymentCodeTabKey" class="ys-my-order-payment-code-container">
						<a-tab-pane key="wechatpayTab" tab="微信付款码">
							<img :src="this.getWechatPayCodeImgUrl(item)" alt="微信二维码" class="ys-my-order-payment-code-img">
						</a-tab-pane>
						<a-tab-pane key="alipayTab" tab="支付宝付款码" force-render>

							<img :src="this.getAliPayCodeImgUrl(item)" alt="支付宝二维码" class="ys-my-order-payment-code-img">
						</a-tab-pane>
					</a-tabs>
				</div>
				<div class="ys-my-order-container-row-action-container" v-if="this.canShowOrderToolbar(item)">
					<div class="ys-my-order-container-row-action" @click="this.confirmPaid(item)"
					     v-if="this.canShowConfirmPaymentBtn(item)">我已付款
					</div>
					<div class="ys-my-order-container-row-text" v-if="this.canShowIWasPaidText(item)">已确认支付</div>
					<div class="ys-my-order-container-row-action" @click="this.confirmBuyerPaid(item)"
					     v-if="this.canShowBuyerPaid(item)">买家已付款
					</div>
					<div class="ys-my-order-container-row-text" v-if="this.canShowBuyerWasPaidText(item)">已确认买家支付</div>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	getMyOrders,
	confirmOrderPayment, confirmOrderBuyerPayment
} from "@/utils/methods";
import eyeOpen from '@/assets/eye_open.svg'
import eyeClosed from '@/assets/eye_closed.svg'
import {Modal} from "ant-design-vue";
import {createVNode} from "vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";

export default {
	name: "AllRecordView",
	async mounted() {
		await this.updateMyOrder()
	},
	data() {
		return {
			paymentCodeTabKey: 'wechatpayTab'
		}
	},
	computed: {
		myOrders() {
			return this.$store.state.myOrders
		}
	},
	methods: {
		async updateMyOrder() {
			const {err, result} = await getMyOrders()
			if (err === false && result?.code === 200) {
				this.$store.state.myOrders = result?.msg.sort((a, b) => {
					return b.createTimestamp - a.createTimestamp
				})
			}
		},
		getProductImageUrl(item) {
			return `https://qrcode.yssc365.com/${item.productId}.jpg`
		},
		getOrderTypeDisplay(item) {
			const userId = this.$store.state.userInfo.userId
			if (item.buyerUserId === userId) {
				return '买入订单'
			}
			if (item.sellerUserId === userId) {
				return '卖出订单'
			}
		},
		getOrderTypeDisplayStyle(item) {
			const userId = this.$store.state.userInfo.userId
			if (item.buyerUserId === userId) {
				return {
					color: 'dodgerblue'
				}
			}
			if (item.sellerUserId === userId) {
				return {
					color: '#FF8400'
				}
			}
		},
		getOnSalePrice(item) {
			return (parseInt(item.amount) * 0.025).toFixed(2)
		},
		async confirmPaid(item) {

			Modal.confirm({
				content: `确认订单已支付？`,
				icon: createVNode(ExclamationCircleOutlined),
				onOk: async function () {

					const {err, result} = await confirmOrderPayment(item.orderId)
					if (err === false && result?.code === 200) {
						this.$message.success({
							content: '操作成功',
							duration: 3 // 设置持续时间为3秒
						});
						await this.updateMyOrder()
					} else {
						this.$message.error({
							content: '操作失败',
							duration: 3 // 设置持续时间为3秒
						});
					}

				}.bind(this),
				cancelText: '取消',
				okText: '确认',
				onCancel() {
					Modal.destroyAll();
				}
			});
		},
		confirmBuyerPaid(item) {

			Modal.confirm({
				content: `确认买家已支付订单？`,
				icon: createVNode(ExclamationCircleOutlined),
				onOk: async function () {

					const {err, result} = await confirmOrderBuyerPayment(item.orderId)
					if (err === false && result?.code === 200) {
						this.$message.success({
							content: '操作成功',
							duration: 3 // 设置持续时间为3秒
						});
						await this.updateMyOrder()
					} else {
						this.$message.error({
							content: '操作失败',
							duration: 3 // 设置持续时间为3秒
						});
					}

				}.bind(this),
				cancelText: '取消',
				okText: '确认',
				onCancel() {
					Modal.destroyAll();
				}
			});
		},
		canShowConfirmPaymentBtn(item) {
			const userId = this.$store.state.userInfo.userId
			if (item.buyerUserId === userId && item.buyerConfirmPayment !== true) {
				return true
			}
			return false
		},
		canShowIWasPaidText(item) {
			const userId = this.$store.state.userInfo.userId
			if (item.buyerUserId === userId && item.buyerConfirmPayment === true) {
				return true
			}
			return false
		},
		canShowBuyerPaid(item) {
			const userId = this.$store.state.userInfo.userId
			if (item.sellerUserId === userId && item.sellerConfirmPayment !== true && item.buyerConfirmPayment === true) {
				return true
			}
			return false
		},
		canShowBuyerWasPaidText(item) {
			const userId = this.$store.state.userInfo.userId
			if (item.sellerUserId === userId && item.sellerConfirmPayment === true) {
				return true
			}
			return false
		},
		getShowPaymentCodeBtnText(item) {
			const userId = this.$store.state.userInfo.userId
			if (item.buyerUserId === userId) {
				if (item.paymentCodeDomain && (item.aliPayCodeUrl || item.wechatPayCodeUrl)) {
					if (item.showPaymentCode) {
						return '隐藏付款码'
					} else {
						return '显示付款码'
					}

				} else {
					return '卖家未上传收款码'
				}
			}

		},
		canShowPaymentCode(item) {
			const userId = this.$store.state.userInfo.userId
			if (item.buyerUserId === userId) {
				return true
			}
			return false
		},
		getShowPaymentCodeBtnIcon(item) {
			if (item.showPaymentCode) {
				return eyeClosed
			} else {
				return eyeOpen
			}
		},
		canShowViewIcon(item) {
			const userId = this.$store.state.userInfo.userId
			if (item.buyerUserId === userId && (item.aliPayCodeUrl || item.wechatPayCodeUrl)) {
				return true
			}
			return false
		},
		onShowPaymentCodeClick(item) {
			const userId = this.$store.state.userInfo.userId
			if (item.buyerUserId === userId && (item.aliPayCodeUrl || item.wechatPayCodeUrl)) {
				item.showPaymentCode = !item.showPaymentCode
			}
		},
		getWechatPayCodeImgUrl (item) {
			return `${item.paymentCodeDomain}${item.wechatPayCodeUrl}`
		},
		getAliPayCodeImgUrl (item) {
			return `${item.paymentCodeDomain}${item.aliPayCodeUrl}`
		},
		getOrderStatusText(item) {
			//wait_for_payment（已指派，等待支付） wait_for_payment_confirm（已支付，等待确认） wait_for_sale （已确认，等待上架） completed （已上架，订单已完成） closed 订单已关闭
			if (item.orderState === 'wait_for_payment') {
				return '等待买家付款'
			}
			if (item.orderState === 'wait_for_payment_confirm') {
				return '买家已付款，等待卖家确认'
			}
			if (item.orderState === 'wait_for_sale') {
				return '卖家已确认收款，等待上架'
			}
			if (item.orderState === 'completed') {
				return '商品已上架，订单已完成'
			}
			if (item.orderState === 'order_closed') {
				return '订单已关闭'
			}
		},
		canShowOrderToolbar(item){
			return item.orderState !== 'order_closed' && item.orderState !== 'completed'
		}
	}
}
</script>
<style scoped>

.ys-my-order-tab-container {

}

.ys-my-order-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 0.4em 0.4em 5em 0.4em;
	box-sizing: border-box;
	overflow-y: auto;
}

.ys-my-order-container-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: #ffffff;
	box-sizing: border-box;
	align-items: center;
}

.ys-my-order-image-container {
	max-width: 8em;
	max-height: 8em;
	margin-right: 0.8em;
}

.ys-my-order-text-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	flex: 1;
}

.ys-my-order-image {
	width: 100%;
}

.ys-my-order-text-cell {
	margin-bottom: 0.2em;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
}

.ys-my-order-text-cell-copy-btn {
	color: #FF8400;
	border: 1px solid #FF8400;
	padding: 0 0.4em;
	font-size: 1em;
	border-radius: 0.2em;
}

.ys-my-order-text-cell-content {
	flex: 1;
	text-align: start;
}

.ys-my-order-container-row-wrapper {
	display: flex;
	flex-direction: column;
	align-items: start;
	width: 100%;
	margin-bottom: 0.8em;
	padding: 0.8em;
	border-radius: 0.4em;
	box-sizing: border-box;
	background-color: #ffffff;

	overflow-y: auto;
}

.ys-my-order-container-row-action-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr; /* 创建两个等宽的列 */
	gap: 0.6em;
	align-items: center;
	justify-content: center;
	padding: 0.6em 0.4em;
	box-sizing: border-box;
	border-top: 1px solid #dedede;
}

.ys-my-order-container-row-action {
	color: dodgerblue;
	padding: 0.2em;
	border: 1px solid dodgerblue;
	border-radius: 0.2em;

}

.ys-my-order-container-row-text{
	color: dodgerblue;
	padding: 0.2em
}

.ys-my-order-input-on-sell-refer-code {
	margin: 1em 0;
}

.ys-my-order-cell-header {
	width: 100%;
	text-align: end;
	display:flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 0.2em;
}

.ys-my-order-display-payment-code-btn{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: dodgerblue;
	padding: 0.4em 0;
}

.ys-my-order-display-payment-code-btn-img{
	margin-left: 0.4em;
}

.ys-my-order-payment-code-container{
	width: 100%;
}

.ys-my-order-payment-code-img{
	width: 100%;
}

</style>