<template>
	<div>
		<router-view v-if="!this.showShopManage()"></router-view>

		<div class="ys-shop-manage-container" v-if="this.showShopManage()">
			<div class="ys-shop-manage-container-row" @click="onOrderManage">
				<div>订单管理</div>
				<img src="../assets/right_arrow.svg" alt="right arrow">
			</div>
			<div class="ys-shop-manage-container-row" @click="onProductManage">
				<div>商品管理</div>
				<img src="../assets/right_arrow.svg" alt="right arrow">
			</div>
			<div class="ys-shop-manage-container-row" @click="onWithdrawRequest">
				<div>提现申请</div>
				<img src="../assets/right_arrow.svg" alt="right arrow">
			</div>
			<div class="ys-shop-manage-container-row" @click="onYuanbaoManage">
				<div class="ys-shop-manage-container-row-text">元宝管理
					<div v-if="this.unclearTechFeeCount > 0"  class="ys-shop-manage-container-row-badge">{{this.unclearTechFeeCount}}</div></div>
				<img src="../assets/right_arrow.svg" alt="right arrow">
			</div>
		</div>
	</div>

</template>

<script>
import {getUnclearTechFeeCount, hasUnclearTechFee} from '@/utils/methods'
export default {
	name: "ShopManageView",
	data() {
		return {
			unclearTechFeeCount: 0
		}
	},
	async mounted() {
		const {err, result} = await getUnclearTechFeeCount()
		if (err === false && result?.code === 200) {
			this.unclearTechFeeCount = result?.msg
		}
	},
	methods: {

		async onOrderManage() {
			const {err, result} = await hasUnclearTechFee()
			if (err === false && result?.code === 200) {
				if (result?.msg === 'true') {
					this.$router.push({name: 'yuanbao'})
				} else {
					this.$router.push({name: 'orderManage'})
				}
			}

		},
		async onProductManage() {
			const {err, result} = await hasUnclearTechFee()
			if (err === false && result?.code === 200) {
				if (result?.msg === 'true') {
					this.$router.push({name: 'yuanbao'})
				} else {
					this.$router.push({name: 'productManage'})
				}
			}
		},
		showShopManage() {
			return this.$route.name === 'shopManage'
		},
		async onWithdrawRequest() {
			const {err, result} = await hasUnclearTechFee()
			if (err === false && result?.code === 200) {
				if (result?.msg === 'true') {
					this.$router.push({name: 'yuanbao'})
				} else {
					this.$router.push({name: 'withdrawRequest'})
				}
			}
		},
		onYuanbaoManage() {
			this.$router.push({name: 'yuanbao'})
		}
	}
}
</script>

<style scoped>
.ys-shop-manage-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: start;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	box-sizing: border-box;
	padding: 0.4em;
}

.ys-shop-manage-container-row {
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: space-between;
	box-sizing: border-box;
	background-color: #ffffff;
	padding: 0.6em 0.4em;
	border-radius: 0.4em;
	width: 100%;
	font-size: 1.2em;
	line-height: 1.2em;
	margin-bottom: 0.4em;
}

.ys-shop-manage-container-row-text{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.ys-shop-manage-container-row-badge{
	color: #ffffff;
	background-color: red;
	width: 1.2em;
	height: 1.2em;
	border-radius: 0.6em;
	margin-left: 0.4em;
}
</style>