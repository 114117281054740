import {createApp} from 'vue'
import App from './App.vue'
import router from './router';
// import Antd from 'ant-design-vue';
import Antd from "ant-design-vue/es";
import store from './store';
import {verifySessionId, hasUnclearTechFee} from './utils/methods'


const app = createApp(App)

router.beforeEach(async (to, from, next) => {
	if (to.name === 'payment') {
		next()
		return
	}


	const isValidSessionId = await verifySessionId()
	if (isValidSessionId) {
		if (to.fullPath.indexOf('main') >= 0) {
			next()
			return
		} else {
			next({name: 'main'})
			return
		}
	}
	// next({name: 'login'})

	if (to.name === 'orderManage' || to.name === 'productManage' || to.name === 'withdrawRequest') {
		const {err, result} = await hasUnclearTechFee()
		if (err === false && result?.code === 200) {
			if (result?.msg === 'true') {
				next({name: 'yuanbao'})
			} else {
				next()
			}
		}
		next({name: 'yuanbao'})
	}
	//
	if (to.name === 'login' || to.name === 'register' || to.name === 'resetPassword') {

		next()

	} else {
		next({name: 'login'})
	}

	// if (to.fullPath.indexOf('main/') >=0 ) {
	// 	if (isValidSessionId) {
	// 		next()
	// 		return
	// 	} else {
	// 		next({name: 'login'})
	// 		return
	// 	}
	// }
	//
	// if (!to.name) {
	// 	if (isValidSessionId) {
	// 		next({name: 'main'})
	// 		return
	// 	} else {
	// 		next({name: 'login'})
	// 		return
	// 	}
	// }
	// if (isValidSessionId) {
	// 	next({name: 'main'})
	// 	return
	//
	// } else {
	// 	next({name: 'login'})
	// }

	next()
});
app.use(router);
app.use(Antd);
app.use(store);

app.mount('#app')
