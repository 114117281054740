<template>
	<div class="ys-info-binding-container">
		<div class="ys-info-binding-account-info-card">
			<div class="ys-info-binding-account-info-card-title">账户信息</div>
			<div class="ys-info-binding-account-info-card-row">
				<div>姓名：</div>
				<div class="ys-info-binding-account-info-card-row-content">{{ this.getUserName }}</div>
			</div>
			<div class="ys-info-binding-account-info-card-row">
				<div>手机号：</div>
				<div class="ys-info-binding-account-info-card-row-content">{{ this.getUserPhoneNum }}</div>
			</div>
			<div class="ys-info-binding-account-info-card-row">
				<div>密码：</div>
				<div class="ys-info-binding-account-info-card-row-content">
					<div class="ys-info-binding-reset-password-btn">重置密码</div>
				</div>
			</div>
			<div class="ys-info-binding-account-info-card-row">
				<div class="ys-info-binding-logout-btn" @click="this.onUserLogout()">退出登录</div>
			</div>
		</div>
		<div class="ys-info-binding-account-info-card">
			<div class="ys-info-binding-account-info-card-title">收款码</div>
			<a-tabs v-model:activeKey="paymentCodeKey" class="ys-info-binding-payment-code-container">
				<a-tab-pane key="wechatpay" tab="微信">
					<div class="ys-info-binding-payment-code-content" v-if="!this.wechatpayCodeUrl">
						<img v-if="this.wechatUploading === false" src="../assets/empty.svg" alt="empty"
						     class="ys-info-binding-payment-code-empty-img" @click="onUploadWechatPayQrCode">
						<div v-if="this.wechatUploading === false" class="ys-info-binding-payment-code-empty-text"
						     @click="onUploadWechatPayQrCode">无内容，点击上传。
						</div>
						<div v-if="this.wechatUploading === true" class="ys-info-binding-img-uploading-container">
							<div>正在上传 {{ this.wechatUploadingPercentage }}%</div>
							<div class="ys-info-binding-img-uploading-progress-bar-container">
								<div class="ys-info-binding-img-uploading-progress-bar"
								     :style="wechatProgressBarStyle()"></div>
							</div>
						</div>
					</div>
					<div v-if="this.wechatpayCodeUrl" class="ys-info-binding-payment-code-img-container">
						<img :src="this.wechatpayCodeUrl" alt="微信二维码" class="ys-info-binding-payment-code-img">

						<a-button type="link" style="margin-top: 2em;" @click="onUploadWechatPayQrCode">重新上传</a-button>
					</div>
				</a-tab-pane>
				<a-tab-pane key="alipay" tab="支付宝" force-render>
					<div class="ys-info-binding-payment-code-content" v-if="!this.alipayCodeUrl">
						<img v-if="this.aliUploading === false" src="../assets/empty.svg" alt="empty" class="ys-info-binding-payment-code-empty-img" @click="onUploadAliPayQrCode">
						<div v-if="this.aliUploading === false" class="ys-info-binding-payment-code-empty-text" @click="onUploadAliPayQrCode">
							无内容，点击上传。
						</div>
						<div v-if="this.aliUploading === true" class="ys-info-binding-img-uploading-container">
							<div>正在上传 {{ this.aliUploadingPercentage }}%</div>
							<div class="ys-info-binding-img-uploading-progress-bar-container">
								<div class="ys-info-binding-img-uploading-progress-bar"
								     :style="aliProgressBarStyle()"></div>
							</div>
						</div>
					</div>
					<div v-if="this.alipayCodeUrl" class="ys-info-binding-payment-code-img-container">
						<img :src="this.alipayCodeUrl" alt="支付宝二维码" class="ys-info-binding-payment-code-img">

						<a-button type="link" style="margin-top: 2em;" @click="onUploadAliPayQrCode">重新上传</a-button>
					</div>
				</a-tab-pane>
			</a-tabs>
			<input type="file" ref="fileInput" @change="handlePaymentCodeUpload" style="display: none;"/>

		</div>
	</div>
</template>

<script>
import axios from 'axios';
import {uploadWechatPaymentCode, getPaymentCodeUrl, orderConfirmProductSale} from "@/utils/methods";
import {Modal} from "ant-design-vue";
import {createVNode} from "vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";

export default {
	name: "InfoBindingView",
	computed: {
		getUserName() {
			return this.$store.state.userInfo?.userName
		},
		getUserPhoneNum() {
			return this.$store.state.userInfo?.phoneNum

		}
	},
	data() {
		return {
			paymentCodeKey: 'wechatpay',
			selectedWechatQrCode: null,
			wechatUploading: false,
			aliUploading: false,
			wechatUploadingPercentage: 0,
			aliUploadingPercentage: 0,
			wechatpayCodeUrl: null,
			alipayCodeUrl: null
		}
	},
	async mounted() {
		const {err, result} = await getPaymentCodeUrl(this.$store.state.userInfo.phoneNum)
		if (err === false) {
			if (result.code === 200) {
				if (result.msg?.wechatPayCodeUrl) {
					this.wechatpayCodeUrl = result.msg?.paymentCodeDomain + result.msg?.wechatPayCodeUrl
				}
				if (result.msg?.aliPayCodeUrl) {
					this.alipayCodeUrl = result.msg?.paymentCodeDomain + result.msg?.aliPayCodeUrl
				}

			}

		}
	},
	methods: {
		onUploadWechatPayQrCode() {
			this.$refs.fileInput.click();
		},
		onUploadAliPayQrCode() {
			this.$refs.fileInput.click();
		},
		handlePaymentCodeUpload(event) {
			this.selectedWechatQrCode = event.target.files[0];
			this.uploadPaymentCode(); // 直接上传文件
		},
		uploadPaymentCode() {
			if (this.selectedWechatQrCode) {
				const formData = new FormData();
				formData.append('file', this.selectedWechatQrCode);
				formData.append('qrCodeType', this.paymentCodeKey);
				formData.append('phoneNum', this.$store.state.userInfo.phoneNum);
				if (this.paymentCodeKey === 'alipay') {
					this.aliUploading = true
				} else {
					this.wechatUploading = true
				}
				uploadWechatPaymentCode(formData, this.updateUploadProgress, this.uploadSuccess, this.uploadFail)
			}
		},
		updateUploadProgress(percentage) {
			console.log(percentage)
			if (this.paymentCodeKey === 'alipay') {
				this.aliUploadingPercentage = percentage
			} else {
				this.wechatUploadingPercentage = percentage
			}
		},
		uploadSuccess(data) {
			console.log('upload success')
			console.log(data)
			this.wechatUploading = false
			this.aliUploading = false
			if (data.code === 200) {
				if (this.paymentCodeKey === 'alipay') {
					this.alipayCodeUrl = data.msg?.paymentCodeUrl
				} else {
					this.wechatpayCodeUrl = data.msg?.paymentCodeUrl
				}

			}
		},
		uploadFail() {
			console.log('upload fail')
			this.wechatUploading = false
			this.aliUploading = false
		},
		wechatProgressBarStyle() {
			return {
				width: this.wechatUploadingPercentage + '%', // 将宽度值转换为字符串，添加 "px"
			};
		},
		aliProgressBarStyle() {
			return {
				width: this.aliUploadingPercentage + '%', // 将宽度值转换为字符串，添加 "px"
				// 你可以在这里添加更多的样式
			};
		},
		onUserLogout () {

			Modal.confirm({
				content: `确认退出登录？`,
				icon: createVNode(ExclamationCircleOutlined),
				onOk: async function () {



					localStorage.removeItem('sessionId')
					localStorage.removeItem('sessionExpireTimeStamp')

					this.$router.push({name: 'login'})
					this.$message.success({
						content: '登出成功',
						duration: 3 // 设置持续时间为3秒
					});

				}.bind(this),
				cancelText: '取消',
				okText: '确认',
				onCancel() {
					Modal.destroyAll();
				}
			});
		}

	}
}
</script>

<style scoped>
.ys-info-binding-container {
	display: flex;
	flex-direction: column;
	padding: 0.8em 0.4em;
	align-items: start;
}

.ys-info-binding-account-info-card {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	padding: 0.8em;
	border-radius: 0.4em;
	box-sizing: border-box;
	background-color: #ffff;
	margin-bottom: 1em;
}

.ys-info-binding-account-info-card-row {
	width: 100%;
	display: flex;
	margin: 0.2em 0;
	padding: 0.4em 0;
	border-bottom: 1px solid #cdcdcd;
	align-items: center;
	justify-content: center;
}

.ys-info-binding-account-info-card-row-content {
	flex: 1;
	text-align: end;
	display: flex;
	align-items: end;
	justify-content: end;
}

.ys-info-binding-reset-password-btn {
	color: #FF8400;
	border: 1px solid #FF8400;
	font-size: 1em;
	justify-content: center;
	display: flex;
	align-items: center;
	border-radius: 1.1em;
	cursor: pointer;
	width: fit-content;
	padding: 0 1em;
}

.ys-info-binding-account-info-card-title {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	font-size: 1.2em;
}

.ys-info-binding-payment-code-container {
	width: 100%;
}

.ys-info-binding-payment-code-empty-img {
	width: 12em;
	padding: 1em;
	opacity: 0.4;
}

.ys-info-binding-payment-code-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ys-info-binding-payment-code-empty-text {
	font-size: 1.2em;
	color: #cdcdcd;
}

.ys-info-binding-img-uploading-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.ys-info-binding-img-uploading-progress-bar {

	height: 0.4em;
	background-color: #FF8400;
	position: absolute;
	top: 0;
	left: 0
}

.ys-info-binding-img-uploading-progress-bar-container {
	position: relative;
	background-color: #cdcdcd;
	width: 100%;
	margin-top: 0.4em;
	height: 0.4em;
}

.ys-info-binding-payment-code-img {
	max-width: 100%;
	max-height: 40em;
}

.ys-info-binding-payment-code-img-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	box-sizing: border-box;
}

.ys-info-binding-logout-btn{
	color: dodgerblue;
	padding: 0.2em 0.8em;
	border: 1px solid dodgerblue;
	border-radius: 0.2em;
}

</style>