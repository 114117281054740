import {createRouter, createWebHistory} from 'vue-router';
import LoginPage from './components/LoginPage.vue';
import MainPage from './components/MainPage.vue';
import AccountCenterView from './components/AccountCenterView.vue';
import RegisterPage from './components/RegisterPage.vue';
import ResetPasswordPage from './components/ResetPasswordPage.vue';
import AccountInfoView from './components/AccountInfoView.vue';
import HomeView from './components/HomeView.vue';
import AllRecordView from './components/AllRecordView.vue';
import MyFansView from './components/MyFansView.vue';
import AddressManageView from './components/AddressManageView.vue';
import ShopManageView from './components/ShopManageView.vue'
import InfoBindingView from './components/InfoBindingView.vue'
import OrderManagerView from './components/OrderManagerView.vue'
import ProductManageView from './components/ProductManageView.vue'
import MyProductView from './components/MyProductView.vue'
import ReservationView from './components/ReservationView.vue'
import AllReservationView from './components/AllReservationView.vue'
import WithdrawRequestView from './components/WithdrawRequestView.vue'
import OrderStatisticView from './components/OrderStatisticView.vue'
import YuanbaoView from './components/YuanbaoView.vue'
import PaymentPage from './components/PaymentPage.vue'

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/main',
			component: MainPage,
			name: 'main',
			redirect: { name: 'accountCenter' },
			children: [
				{
					// 当匹配到 /parent/child 的时候
					// ChildComponent 会被渲染在 ParentComponent 的 <router-view> 中
					path: 'accountCenter',
					name: 'accountCenter',
					component: AccountCenterView,
					meta: {
						title: '分销中心'
					},
					children: [
						{
							path: 'myFans',
							component: MyFansView,
							name: 'myFans',
							meta: {
								title: '我的粉丝'
							}
						}
					]
				},
				{
					path: 'accountInfo',
					component: AccountInfoView,
					name: 'accountInfo',
					meta: {
						title: '账户信息'
					},
					children: [
						{
							path: 'addressManage',
							component: AddressManageView,
							name: 'addressManage',
							meta: {
								title: '地址管理'
							}
						},
						{
							path: 'shopManage',
							component: ShopManageView,
							name: 'shopManage',
							meta: {
								title: '商户管理'
							},
							children: [
								{
									path: 'orderManage',
									name: 'orderManage',
									component: OrderManagerView,
									meta: {
										title: '订单管理'
									}
								},
								{
									path: 'productManage',
									name: 'productManage',
									component: ProductManageView,
									meta: {
										title: '商品管理'
									}
								},
								{
									path: 'withdrawRequest',
									name: 'withdrawRequest',
									component: WithdrawRequestView,
									meta: {
										title: '提现申请'
									}
								},
								{
									path: 'yuanbao',
									name: 'yuanbao',
									component: YuanbaoView,
									meta: {
										title: '元宝管理'
									}
								}
							]
						},
						{
							path: 'infoBinding',
							component: InfoBindingView,
							name: 'infoBinding',
							meta: {
								title: '信息绑定'
							}
						},
						{
							path: 'myProduct',
							component: MyProductView,
							name: 'myProduct',
							meta: {
								title: '我的商品'
							}
						},
						{
							path: 'orderStats',
							component: OrderStatisticView,
							name: 'orderStats',
							meta: {
								title: '订单统计'
							}
						}
					]
				},
				{
					path: 'home',
					component: HomeView,
					name: 'home',
					meta: {
						title: '首页'
					},
					children: [
						{
							path: 'reservation',
							name: 'reservation',
							component: ReservationView,
							meta: {
								title: '预约'
							}
						},
						{
							path: 'reservationList',
							name: 'reservationList',
							component: AllReservationView,
							meta: {
								title: '预约列表'
							}
						}
					]
				},
				{
					path: 'record',
					component: AllRecordView,
					name: 'record',
					meta: {
						title: '订单记录'
					}
				}
			]
		},
		{
			path: '/login',
			component: LoginPage,
			name: 'login'
		},
		{
			path: '/register',
			component: RegisterPage,
			name: 'register'
		},
		{
			path: '/resetPassword',
			component: ResetPasswordPage,
			name: 'resetPassword',
			props: true
		},
		{
			path: '/payment',
			component: PaymentPage,
			name: 'payment',
			props: true
		}
	]
});

export default router;