<template>
	<div class="ys-main-page-container">
		<div class="ys-main-page-header">
			<img v-if="this.showHeaderBackBtn" src="../assets/left_arrow.svg" class="ys-main-page-header-back" alt="left arrow" @click="this.onGoBack()">
			{{ this.pageTitle }}
		</div>
		<router-view class="ys-main-router-view-container"></router-view>
		<MenuToolbar v-if="this.isShowToolbar()"/>
	</div>
</template>

<script>
import MenuToolbar from './MenuToolbar.vue'
import {getUserInfoBySessionId} from "@/utils/methods";
import { message } from 'ant-design-vue';
export default {
	name: "MainPage",
	components: {
		MenuToolbar
	},
	data() {
		return {
			pageTitle: '',
			pageType: 'home',
			showHeaderBackBtn: false
		};
	},
	async mounted() {
		const title = this.$route.meta?.title
		if (title) {
			this.pageTitle = title
			document.title = title
		}

		const {err, result} = await getUserInfoBySessionId()
		if (err === false) {
			if (result.code === 200) {
				this.$store.dispatch('setUserInfo', result.msg)
			} else {
				message.error('会员信息获取失败')
			}
		}
		this.updateBackBtn()
	},
	watch: {
		// 使用字符串方法监听路由变化
		'$route'(to, from) {

			this.updateBackBtn()
			const title = this.$route.meta?.title
			if (title) {
				this.pageTitle = title
				document.title = title
			}
		}
	},
	methods: {
		updateBackBtn() {
			const fullPath = this.$route.fullPath
			const routeDeep = fullPath.split('/').filter(item => item !== null && item !== undefined && item !== '').length
			if (routeDeep > 2) {
				this.showHeaderBackBtn = true
			} else {
				this.showHeaderBackBtn = false
			}
		},
		onGoBack() {
			this.$router.back()
		},
		isShowToolbar() {
			return this.$route.matched.length <= 2
		}
	}
}
</script>

<style scoped>

@media (min-width: 1201px) {
	.ys-main-page-container {
		margin: 0 20em !important;
	}


}
@media (min-width: 901px) and (max-width: 1200px) {
	.ys-main-page-container {
		margin: 0 10em !important;
	}


}
.ys-main-page-container {
	width: 100%;
	height: 100%;;
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: #F4F5F6;
}

.ys-main-page-header {
	width: 100%;
	background-color: rgb(239, 239, 239);
	padding: 0.6em;
	line-height: 1.2em;
	font-size: 1.2em;
	z-index: 3;
	position: relative;
	box-sizing: border-box;
}
.ys-main-page-header-back{
	position: absolute;
	left: 1em;
	width: 1.6em;
	top: 50%;
	transform: translate(-50%, -50%);
}

.ys-main-router-view-container{
	flex: 1;
	overflow-y: auto;
}
</style>