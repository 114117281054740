<template>
	<router-view></router-view>
</template>

<script>
import MainPage from './components/MainPage.vue'

export default {
	name: 'App',
	components: {

	}
}
</script>

<style>

:root {
	--primary-color: #FF8900;
	--background-color: #1f1f35;
	--white-color: #E6D5B8;
	--red-color: #e45826;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
	position:fixed;
	top:0;
	left:0;
	overflow: hidden;
}


.ys-info-binding-payment-code-container > .ant-tabs-nav > .ant-tabs-nav-wrap {
	display: flex;
	justify-content: center !important;
}
.ys-my-order-payment-code-container > .ant-tabs-nav > .ant-tabs-nav-wrap {
	display: flex;
	justify-content: center !important;
}
.ys--make-reservation-tab-container > .ant-tabs-nav > .ant-tabs-nav-wrap {
	display: flex;
	justify-content: center !important;
}


</style>
