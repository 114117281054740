import Vuex from "vuex";

export default new Vuex.Store({
	state: {
		userInfo: {
			userId: null,
			role: null,
			userName: null,
			phoneNum: null,
			referCode: null,
			shopName: null,
			referByUserName: null,
			referByPhoneNum: null
		},
		referMemberList: [],
		activeProductList: [],
		closedProductList: [],
		orderList: [],
		myProducts: [],
		myOrders: [],
		myReservationList: [],
		allReservationList: [],
		allMemberList: [],
		myDividendList: [],
		withdrawRequestList: [],
		myTeamOrderList: [],
		allOrderStats: {
			orderCreateCount: 0,
			orderCreateAmount: 0,
			orderCompletedCount: 0,
			orderCompletedAmount: 0
		}
	},
	mutations: {
		UPDATE_USER_INFO(state, payload) {
			state.userInfo = payload;
		},
		UPDATE_REFER_MEMBER_LIST(state, payload) {
			state.referMemberList = payload;
		},
		UPDATE_ACTIVE_PRODUCT_LIST(state, payload) {
			state.activeProductList = payload;
		},
		UPDATE_CLOSED_PRODUCT_LIST(state, payload) {
			state.closedProductList = payload;
		},
		UPDATE_ORDER_LIST(state, payload) {
			state.orderList = payload;
		},
		APPEND_ORDER_LIST(state, payload) {
			state.orderList = state.orderList.concat(payload);
		},
		SET_LAST_ORDER_LIST(state) {
			state.orderList[state.orderList.length -1].isAll = true
		},
		UPDATE_MY_PRODUCTS(state, payload) {
			state.myProducts = payload;
		},
		UPDATE_MY_ORDERS(state, payload) {
			state.myOrders = payload;
		},
		UPDATE_MY_RESERVATION(state, payload) {
			state.myReservationList = payload;
		},
		UPDATE_ALL_RESERVATION(state, payload) {
			state.allReservationList = payload;
		},
		UPDATE_ALL_MEMBER_LIST(state, payload) {
			state.allMemberList = payload;
		},
		UPDATE_MY_DIVIDEND_LIST(state, payload) {
			state.myDividendList = payload;
		},
		UPDATE_WITHDRAW_REQUEST_LIST(state, payload) {
			state.withdrawRequestList = payload;
		},
		UPDATE_MY_TEAM_ORDER_LIST(state, payload) {
			state.myTeamOrderList = payload;
		},
		UPDATE_ALL_ORDER_STATS(state, payload) {
			state.allOrderStats = payload;
		}
	},
	actions: {
		setUserInfo({ commit }, payload) {
			commit('UPDATE_USER_INFO', payload);
		},
		setReferMemberList({ commit }, payload) {
			commit('UPDATE_REFER_MEMBER_LIST', payload);
		},
		setActiveProductList({ commit }, payload) {
			commit('UPDATE_ACTIVE_PRODUCT_LIST', payload);
		},
		setClosedProductList({ commit }, payload) {
			commit('UPDATE_CLOSED_PRODUCT_LIST', payload);
		},
		setOrderList({ commit }, payload) {
			commit('UPDATE_ORDER_LIST', payload);
		},
		appendOrderList({ commit }, payload) {
			commit('APPEND_ORDER_LIST', payload);
		},
		setLastOrderList({ commit }) {
			commit('SET_LAST_ORDER_LIST');
		},
		setMyProductList({ commit }, payload) {
			commit('UPDATE_MY_PRODUCTS', payload);
		},
		setMyOrderList({ commit }, payload) {
			commit('UPDATE_MY_ORDERS', payload);
		},
		setMyReservationList({ commit }, payload) {
			commit('UPDATE_MY_RESERVATION', payload);
		},
		setAllReservationList({ commit }, payload) {
			commit('UPDATE_ALL_RESERVATION', payload);
		},
		setAllMemberList({ commit }, payload) {
			commit('UPDATE_ALL_MEMBER_LIST', payload);
		},
		setMyDividendList({ commit }, payload) {
			commit('UPDATE_MY_DIVIDEND_LIST', payload);
		},
		setWithdrawRequestList({ commit }, payload) {
			commit('UPDATE_WITHDRAW_REQUEST_LIST', payload);
		},
		setMyTeamOrderList({ commit }, payload) {
			commit('UPDATE_MY_TEAM_ORDER_LIST', payload);
		},
		setAllOrderStats({ commit }, payload) {
			commit('UPDATE_ALL_ORDER_STATS', payload);
		}
	}
});