<template>
	<div class="ys-yuan-bao-container">


		<a-modal v-model:open="openSelectPaymentMethod" :title="`选择支付方式`">
			<template #footer>
				<a-button key="submit" @click="cancelPaidCodeModel">取消</a-button>
				<a-button key="back" type="primary" @click="confirmPaymentMethod">确定</a-button>
			</template>
			<div class="ys-yuan-bao-select-payment-method-container">
				<div class="ys-yuan-bao-select-payment-method-row" @click="paymentMethodClicked('wechat_pay')">
					<img :src="this.getRadioImage('wechat_pay')" alt="radio" class="ys-yuan-bao-payment-method-radio-icon">
					<img src="../assets/wechat_pay.svg" alt="wechat pay" class="ys-yuan-bao-payment-method-icon">
					<div>微信支付</div>
				</div>
				<div class="ys-yuan-bao-select-payment-method-row" @click="paymentMethodClicked('ali_pay')">
					<img :src="this.getRadioImage('ali_pay')" alt="ali pay" class="ys-yuan-bao-payment-method-radio-icon">
					<img src="../assets/ali_pay.svg" alt="wechat pay" class="ys-yuan-bao-payment-method-icon">
					<div>支付宝</div>
				</div>
			</div>
		</a-modal>
		<div v-if="this.feeList.length === 0" class="ys-yuan-bao-empty">
			无未结算项
		</div>
		<div v-for="item in this.feeList" :key="item.recordId" class="ys-yuan-bao-container-wrapper">
			<div
				class="ys-yuan-bao-container-row-wrapper">
				<div class="ys-yuan-bao-cell-header" :style="getFeeStateStyle(item)">{{
						this.getFeeStateText(item)
					}}
				</div>
				<div class="ys-yuan-bao-text-cell">
					<div class="ys-yuan-bao-text-cell-content" style="font-weight: bold;">
						日期：
						{{ item.feeDate }}
					</div>
				</div>
				<div class="ys-yuan-bao-text-cell">
					<div class="ys-yuan-bao-text-cell-content">成交额：{{ item.orderTotalAmount }}</div>
				</div>
				<div class="ys-yuan-bao-text-cell">
					<div class="ys-yuan-bao-text-cell-content">费率：{{ item.feeRate }}</div>
				</div>
				<div class="ys-yuan-bao-text-cell">
					<div class="ys-yuan-bao-text-cell-content">结算金额：{{ item.feeAmount }}</div>
				</div>
				<div class="ys-yuan-bao-text-cell" v-if="this.canShowPaidCode(item)">
					<div class="ys-yuan-bao-text-cell-content">口令：{{ this.getPaidCodeText(item) }}</div>
					<img :src="this.getViewPaidCodeImg(item)" alt="eye_open" class="ys-yuan-bao-paid-code-view-img"
					     @click="this.showPaidCodeClicked(item)">
				</div>
				<div class="ys-yuan-bao-container-row-action-container"
				     v-if="this.canShowBtnContainer(item)">
					<div class="ys-yuan-bao-container-row-action" @click="this.payBill(item)"
					     v-if="this.canShowPayBill(item)">支付
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	getUnclearFee,
	confirmTechFeePayment,
	confirmTechFeeReceived, getTecFeePaidCode, getUnclearFeeTest,
	getPaymentLink
} from "@/utils/methods";
import {Modal} from "ant-design-vue";
import {createVNode} from "vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import eyeOpen from '@/assets/eye_open.svg'
import eyeClosed from '@/assets/eye_closed.svg'
import radioEmpty from '@/assets/radio_empty.svg'
import radioFull from '@/assets/radio_full.svg'

export default {
	name: "YuanbaoView",
	computed: {},
	data() {
		return {
			feeList: [],
			openSelectPaymentMethod: false,
			paidCode: null,
			selectedItem: null,
			selectedPaymentMethod: 'wechat_pay' // ali_pay
		}
	},
	async mounted() {
		await this.getUnclearFee()
	},
	methods: {
		getRadioImage(method) {
			if (method === this.selectedPaymentMethod) {
				return radioFull
			}
			return radioEmpty
		},
		paymentMethodClicked(method) {
			this.selectedPaymentMethod = method
		},

		async getUnclearFee() {
			const {err, result} = await getUnclearFee()
			if (err === false && result?.code === 200) {
				this.feeList = result.msg
			}
		},
		getFeeStateText(item) {
			if (item.isClear === true) {
				return '已结算'
			}
			return '未结算'

		},
		canShowBtnContainer(item) {
			if (item.isClear === true) {
				return false
			}
			return true
		},
		canShowPayBill(item) {
			if (item.isClear === true) {
				return false
			}
			return true
		},
		async payBill(item) {
			this.selectedItem = item
			this.openSelectPaymentMethod = true
		},
		getFeeStateStyle(item) {
			if (item.isClear === true) {
				return {
					color: 'green'
				}
			}
			return {
				color: 'red'
			}
		},
		cancelPaidCodeModel() {
			this.openSelectPaymentMethod = false
		},
		async confirmPaymentMethod() {

			let paymentMethod = 'wxpay'
			if (this.selectedPaymentMethod === 'ali_pay') {
				paymentMethod = 'alipay'
			}
			const {err, result} = await getPaymentLink(this.selectedItem.recordId, paymentMethod)
			if (err === false && result?.code === 200 && result?.msg) {
				window.open(result?.msg, '_blank');
				this.openSelectPaymentMethod = false
			}
		},
		async showPaidCodeClicked(item) {
			if (item.paidCode.indexOf('*') < 0) {
				item.showPaidCode = !item.showPaidCode
				return
			}
			if (item.showPaidCode === true) {
				item.showPaidCode = false
				return
			}
			const {err, result} = await getTecFeePaidCode(item.recordId)
			if (err === false && result?.code === 200) {
				item.paidCode = result?.msg
				item.showPaidCode = true
			}
		},
		getViewPaidCodeImg(item) {
			if (item.showPaidCode === true) {
				return eyeClosed
			}
			return eyeOpen
		},
		getPaidCodeText(item) {
			if (item.paidCode.indexOf('*') < 0) {
				if (item.showPaidCode === true) {
					return item.paidCode
				} else {
					return item.paidCode.replace(/[a-zA-Z0-9]/g, '*')
				}

			}
			return item.paidCode
		},
		canShowPaidCode(item) {
			if (!item.paidCode) {
				return false
			}
			if (item.isClear === true) {
				return false
			}

			return true
		}
	}
}
</script>
<style scoped>
.ys-yuan-bao-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	overflow-x: hidden;
	box-sizing: border-box;
	padding: 0.4em;
}

.ys-yuan-bao-empty {
	width: 100%;
	padding: 1em;
	box-sizing: border-box;
}

.ys-yuan-bao-container-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	padding: 1em;
	background-color: #ffffff;
	border-radius: 0.4em;
	box-sizing: border-box;
	margin: 0.4em 0;
}

.ys-yuan-bao-text-cell {
	width: 100%;
	text-align: start;
	margin-bottom: 0.2em;
	display: flex;
	flex-direction: row;
	justify-content: start;
}

.ys-yuan-bao-cell-header {
	width: 100%;
	text-align: end;
	margin-bottom: 0.2em

}

.ys-yuan-bao-container-row-wrapper {
	width: 100%;
}

.ys-yuan-bao-container-row-action-container {

	margin-top: 0.6em;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.ys-yuan-bao-container-row-action {
	color: dodgerblue;
	padding: 0.2em 1em;
	border: 1px solid dodgerblue;
	border-radius: 0.2em;
	width: fit-content;
}

.ys-yuan-bao-paid-code-view-img {
	margin-left: 1em;
}

.ys-yuan-bao-select-payment-method-container {
	display: flex;
	flex-direction: column;
	align-items: start;
}

.ys-yuan-bao-payment-method-icon {
	width: 1.4em;
	margin: 0 0.4em;
}

.ys-yuan-bao-select-payment-method-row {
	display: flex;
	flex-direction: row;
	margin: 0.4em 0;
}

.ys-yuan-bao-payment-method-radio-icon{
	width: 1.4em;
}
</style>