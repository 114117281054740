<template>
	<div style="overflow-y: scroll;">
		<router-view v-if="!this.isShowAccountInfo()"></router-view>
		<div v-if="this.isShowAccountInfo()" class="ys-account-info-view-container">
			<div class="ys-account-info-header">
				<div class="ys-account-info-header-row">
					<div class="ys-account-info-header-name-container">
						<div class="ys-account-info-name-text">{{ this.getUserAccountName }}</div>
						<div class="ys-account-info-invitation-text" @click="copyReferCode">邀请码： {{
								this.getReferCode
							}} （点击复制）
						</div>
					</div>
					<div class="ys-account-info-header-invitation-btn">邀请入驻</div>
				</div>
			</div>

			<div class="ys-account-info-balance-container">
				<div class="ys-account-info-balance-text">我的余额：{{ this.myDividendAmount }}</div>
				<div class="ys-account-info-balance-withdraw-btn" @click="this.onWithdrawDividend()">
					{{ this.getRequestDividendText() }}
				</div>
			</div>

			<div class="ys-account-info-detail-container">
				<div class="ys-account-info-detail-row">
					<div class="ys-account-info-detail-row-1">
						<img class="ys-account-info-detail-row-img"
						     src="../assets/order.svg" alt="order">我的订单
					</div>
					<div class="ys-account-info-detail-row-1">
						<div>查看全部</div>
						<img src="../assets/right_arrow.svg" alt="right arrow"></div>
				</div>
				<div class="ys-account-info-detail-row">
					<div class="ys-account-info-detail-item">
						<img class="ys-account-info-detail-item-img" src="../assets/dengdaifukuan.png" alt="pay">
						<div class="ys-account-info-detail-item-text">等待付款</div>
					</div>
					<div class="ys-account-info-detail-item">
						<img class="ys-account-info-detail-item-img" src="../assets/shoukuanqueren.png"
						     alt="shoukuanqueren">
						<div class="ys-account-info-detail-item-text">收款确认</div>
					</div>
					<div class="ys-account-info-detail-item">
						<img class="ys-account-info-detail-item-img" src="../assets/weituodaimai.png" alt="pay">
						<div class="ys-account-info-detail-item-text">委托代卖</div>
					</div>
				</div>
				<div class="ys-account-info-detail-row">
					<div class="ys-account-info-detail-item-1">
						<img class="ys-account-info-detail-item-img" src="../assets/shangjialeiji.png" alt="pay">
						<div class="ys-account-info-detail-item-1-text-container">
							<div>上架累计</div>
							<div>0</div>
						</div>
					</div>
					<div class="ys-account-info-detail-item-1">
						<img class="ys-account-info-detail-item-img" src="../assets/yiduihuandingyue.png"
						     alt="yiduihuandingyue">
						<div class="ys-account-info-detail-item-1-text-container">
							<div>已兑换订阅</div>
							<div>0</div>
						</div>
					</div>
				</div>
				<div class="ys-account-info-detail-row" @click="onShopManage" v-if="this.getMyRole === 'storeManager' || this.getMyRole === 'admin'">
					<div class="ys-account-info-detail-row-1">
						<img class="ys-account-info-detail-row-img"
						     src="../assets/shgl.png" alt="order">商户管理
					</div>
					<div class="ys-account-info-detail-row-1">
						<div>查看</div>
						<img src="../assets/right_arrow.svg" alt="right arrow"></div>
				</div>
				<div class="ys-account-info-detail-row" @click="onInfoBinding">
					<div class="ys-account-info-detail-row-1">
						<img class="ys-account-info-detail-row-img"
						     src="../assets/xxbd.png" alt="order">信息绑定
					</div>
					<div class="ys-account-info-detail-row-1">
						<div>查看</div>
						<img src="../assets/right_arrow.svg" alt="right arrow"></div>
				</div>
				<div class="ys-account-info-detail-row" @click="this.onAddressManageView()">
					<div class="ys-account-info-detail-row-1">
						<img class="ys-account-info-detail-row-img"
						     src="../assets/dzgl.png" alt="order">地址管理
					</div>
					<div class="ys-account-info-detail-row-1">
						<div>查看</div>
						<img src="../assets/right_arrow.svg" alt="right arrow"></div>
				</div>
				<div class="ys-account-info-detail-row" @click="onMyProductClicked">
					<div class="ys-account-info-detail-row-1">
						<img class="ys-account-info-detail-row-img"
						     src="../assets/wdsp.png" alt="order">我的商品
					</div>
					<div class="ys-account-info-detail-row-1">
						<div>查看</div>
						<img src="../assets/right_arrow.svg" alt="right arrow"></div>
				</div>
				<div class="ys-account-info-detail-row" @click="onOrderStatsClicked">
					<div class="ys-account-info-detail-row-1">
						<img class="ys-account-info-detail-row-img"
						     src="../assets/wdhy.png" alt="order">订单统计
					</div>
					<div class="ys-account-info-detail-row-1">
						<div>查看</div>
						<img src="../assets/right_arrow.svg" alt="right arrow"></div>
				</div>
				<div class="ys-account-info-detail-row">
					<div class="ys-account-info-detail-row-1">
						<img class="ys-account-info-detail-row-img"
						     src="../assets/wdjyb.png" alt="order">我的金元宝
					</div>
					<div class="ys-account-info-detail-row-1">
						<div>查看</div>
						<img src="../assets/right_arrow.svg" alt="right arrow"></div>
				</div>
				<div class="ys-account-info-detail-row">
					<div class="ys-account-info-detail-row-1">
						<img class="ys-account-info-detail-row-img"
						     src="../assets/wdxfq.png" alt="order">我的消费券
					</div>
					<div class="ys-account-info-detail-row-1">
						<div>查看</div>
						<img src="../assets/right_arrow.svg" alt="right arrow"></div>
				</div>
				<div class="ys-account-info-detail-row">
					<div class="ys-account-info-detail-row-1">
						<img class="ys-account-info-detail-row-img"
						     src="../assets/mh.png" alt="order">盲盒
					</div>
					<div class="ys-account-info-detail-row-1">
						<div>查看</div>
						<img src="../assets/right_arrow.svg" alt="right arrow"></div>
				</div>
				<div class="ys-account-info-detail-row">
					<div class="ys-account-info-detail-row-1">
						<img class="ys-account-info-detail-row-img"
						     src="../assets/hgc.png" alt="order">回购仓
					</div>
					<div class="ys-account-info-detail-row-1">
						<div>查看</div>
						<img src="../assets/right_arrow.svg" alt="right arrow"></div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>

import copy from "copy-to-clipboard";
import {getMyDividends, requestDividend, checkIfDividendRequested} from "@/utils/methods";
import {Modal} from "ant-design-vue";
import {createVNode} from "vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";

export default {
	name: "AccountInfoView",
	data() {
		return {
			dividendRequested: false
		}
	},
	computed: {
		getUserAccountName() {
			return `${this.$store.state.userInfo.phoneNum} (${this.$store.state.userInfo.userName})`
		},
		getReferCode() {
			return this.$store.state.userInfo.referCode
		},
		getMyRole() {
			return this.$store.state.userInfo.role
		},
		myDividendAmount() {
			if (!this.$store.state.myDividendList || !Array.isArray(this.$store.state.myDividendList)) {
				return '0.00'
			}
			let toReturn = 0
			for (const item of this.$store.state.myDividendList) {
				if (item.isRequested !== true) {
					toReturn += parseFloat(item.dividendAmount)
				}

			}

			return toReturn.toFixed(2)
		}
	},
	async mounted() {
		await this.getMyDividends()
		await this.isDividendRequested()
	},
	methods: {
		async isDividendRequested() {
			const {err, result} = await checkIfDividendRequested()

			if (err === false && result?.code === 200 && result.msg) {
				this.dividendRequested = true
				return
			}
			this.dividendRequested = false
		},
		async getMyDividends() {
			const {err, result} = await getMyDividends()
			if (err === false && result.code === 200) {
				this.$store.dispatch('setMyDividendList', result.msg)
			}
		},
		copyReferCode() {
			const copyResult = copy(this.$store.state.userInfo.referCode);
			if (copyResult === true) {
				this.$message.success({
					content: '复制成功',
					duration: 3 // 设置持续时间为3秒
				});
			} else {

				this.$message.success({
					content: '复制失败',
					duration: 3 // 设置持续时间为3秒
				});
			}
		},
		onAddressManageView() {
			this.$router.push({name: 'addressManage'})

		},
		isShowAccountInfo() {
			return this.$route.name === 'accountInfo'
		},
		onShopManage() {
			this.$router.push({name: 'shopManage'})
		},
		onInfoBinding() {
			this.$router.push({name: 'infoBinding'})

		},
		onMyProductClicked() {
			this.$router.push({name: 'myProduct'})
		},
		onWithdrawDividend() {

			if (parseFloat(this.myDividendAmount) <= 0) {
				this.$message.info({
					content: '没有可提现余额',
					duration: 3 // 设置持续时间为3秒
				});
				return
			}

			if (parseFloat(this.myDividendAmount) < 50) {
				this.$message.info({
					content: '最小提现额：50',
					duration: 3 // 设置持续时间为3秒
				});
				return
			}

			if (this.dividendRequested === true) {
				this.$message.info({
					content: '已发起提现申请，等待处理。',
					duration: 3 // 设置持续时间为3秒
				});
				return
			}

			Modal.confirm({
				content: `确认提现？`,
				icon: createVNode(ExclamationCircleOutlined),
				onOk: async function () {
					const dividendIdList = []
					for (const item of this.$store.state.myDividendList) {
						dividendIdList.push(item.recordId)
					}
					const {err, result} = await requestDividend(dividendIdList)

					if (err === false && result?.code === 200) {

						this.$message.success({
							content: '操作成功',
							duration: 3 // 设置持续时间为3秒
						});

						await this.getMyDividends()
						await this.isDividendRequested()
						return

					}

					this.$message.error({
						content: '操作失败，联系管理员',
						duration: 3 // 设置持续时间为3秒
					});

				}.bind(this),
				cancelText: '取消',
				okText: '确认',
				onCancel() {
					Modal.destroyAll();
				}
			});
		},
		getRequestDividendText() {
			if (this.dividendRequested === true) {
				let requestedAmount = 0
				for (const item of this.$store.state.myDividendList) {
					if (item.isRequested === true) {
						requestedAmount += parseFloat(item.dividendAmount)
					}

				}
				return `已申请(${requestedAmount.toFixed(2)})`
			}
			return '提现'
		},
		onOrderStatsClicked() {
			this.$router.push({name: 'orderStats'})
		}

	}
}
</script>

<style scoped>
.ys-account-info-view-container {
	padding-bottom: 5em;
	background-color: #ffff;
}

.ys-account-info-header {

	background: rgb(231, 191, 146);
	background: linear-gradient(90deg, rgba(231, 191, 146, 1) 0%, rgba(245, 236, 203, 1) 100%);
	display: flex;
	flex-direction: column;

}

.ys-account-info-header-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	box-sizing: border-box;
	padding: 1em;
}

.ys-account-info-header-name-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	flex: 1;
}

.ys-account-info-header-invitation-btn {
	color: #f50;
	font-size: 1em;
	height: 2em;
	line-height: 1em;
	padding: 0 1em;
	border-radius: 1em;
	border: 1px solid #f50;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ys-account-info-name-text {
	color: #ffff;
}

.ys-account-info-invitation-text {
	color: #ffff;
}

.ys-account-info-balance-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 1em;
	padding: 1em;
	background-color: #ffc894;
	border-radius: 0.4em;
	font-size: 1em;
}

.ys-account-info-balance-withdraw-btn {
	color: #fff;
	background-color: #a00;
	font-size: 1em;
	height: 2em;
	line-height: 1em;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 1.4em;
	border-radius: 1em;
}

.ys-account-info-balance-text {
	font-weight: bold;
}

.ys-account-info-detail-container {
	display: flex;
	flex-direction: column;

}

.ys-account-info-detail-row {
	display: flex;
	flex-direction: row;
	margin: 0 1em;
	padding: 0.8em;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e6e6e6;
}

.ys-account-info-detail-row-1 {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.ys-account-info-detail-row-img {
	margin-right: 0.4em;
	width: 1.8em;
}

.ys-account-info-detail-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
}

.ys-account-info-detail-item-1 {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 1;
}

.ys-account-info-detail-item-img {
	height: 4em;
}

.ys-account-info-detail-item-text {
}

.ys-account-info-detail-item-1-text-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	margin-left: 0.4em;
}
</style>