<template>
	<div>地址管理</div>
</template>

<script>
export default {
	name: "AddressManageView",
	computed: {
	},
	methods: {

	}
}
</script>
<style scoped>

</style>