<template>
	<div class="ys-main-menu-toolbar">
		<div class="ys-main-menu-item" @click="onMainMenuItemClicked('home')">
			<img class="ys-main-menu-item-icon" :src="getCurrentImageUrl('home')" alt="sy">
		</div>
		<div class="ys-main-menu-item" @click="onMainMenuItemClicked('record')">

			<img class="ys-main-menu-item-icon" :src="getCurrentImageUrl('record')" alt="qbjl">
		</div>
		<div class="ys-main-menu-item" @click="onMainMenuItemClicked('accountCenter')">
			<img class="ys-main-menu-item-icon" :src="getCurrentImageUrl('accountCenter')" alt="jzzx">
		</div>
		<div class="ys-main-menu-item" @click="onMainMenuItemClicked('accountInfo')">
			<img class="ys-main-menu-item-icon" :src="getCurrentImageUrl('accountInfo')" alt="grzx">
		</div>
	</div>
</template>

<script>
import syOff from '../assets/sy_off.png'
import sy from '../assets/sy.png'
import qbjl from '../assets/qbjl.png'
import qbjlOff from '../assets/qbjl_off.png'
import jzzx from '../assets/jzzx.png'
import jzzxOff from '../assets/jzzx_off.png'
import grzx from '../assets/grzx.png'
import grzxOff from '../assets/grzx_off.png'
import {getActivateMenuCodeByPath} from "@/utils/methods";
export default {
	name: "MenuToolbar",
	computed: {

	},
	data() {
		return {
			currentSelectedItem: ''
		};
	},
	mounted() {
		console.log(this.$route.path)
		this.currentSelectedItem = this.$route.name
	},
	watch: {
		// 使用字符串方法监听路由变化
		'$route'(to, from) {

			this.currentSelectedItem = this.$route.name
		}
	},
	methods: {
		onMainMenuItemClicked(type) {
			this.currentSelectedItem = type

			this.$router.push({name: type})
		},
		getCurrentImageUrl (type) {
			if (type === 'home') {
				if (type === this.currentSelectedItem) {
					return sy
				}
				return syOff
			}
			if (type === 'record') {
				if (type === this.currentSelectedItem) {
					return qbjl
				}
				return qbjlOff
			}
			if (type === 'accountCenter') {
				if (type === this.currentSelectedItem) {
					return jzzx
				}
				return jzzxOff
			}
			if (type === 'accountInfo') {
				if (type === this.currentSelectedItem) {
					return grzx
				}
				return grzxOff
			}

			return '../assets/sy_off.png'
		}
	}
}
</script>

<style scoped>
@media (min-width: 900px) {
	.ys-main-menu-toolbar{
		z-index: 8;
	}
}

.ys-main-menu-toolbar {
	width: 100%;
	display: flex;
	flex-direction: row;
	bottom: 0;
	left: 0;
	height: 4em;
	background-color: #ffff;
	box-shadow: 4px 4px 10px 5px rgba(0, 0, 0, 0.1);
}

.ys-main-menu-item {
	flex: 1;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1em;
}

.ys-main-menu-item-icon {
	width: 3em;
}
</style>