<template>
	<div class="ys-my-product-container">
		<div v-for="item in this.myProductList" :key="item.productId" class="ys-my-product-container-row-wrapper">
			<div class="ys-my-product-cell-header">已上架</div>
			<div class="ys-my-product-container-row">
				<div class="ys-my-product-image-container">
					<img class="ys-my-product-image" :src=this.getProductImageUrl(item) alt="商品图片">
				</div>
				<div class="ys-my-product-text-container">
					<div class="ys-my-product-text-cell">
						<div class="ys-my-product-text-cell-content" style="font-weight: bold;">{{
								item.productCode
							}}
						</div>
					</div>
					<div class="ys-my-product-text-cell">
						<div class="ys-my-product-text-cell-content">持有人：{{ this.getOwner() }}</div>
					</div>
<!--					<div class="ys-my-product-text-cell">-->
<!--						<div class="ys-my-product-text-cell-content">推荐码：{{ item.referCode }}</div>-->
<!--						<div class="ys-my-product-text-cell-copy-btn" @click="copyUserReferCode(item.referCode)">-->
<!--							复制-->
<!--						</div>-->
<!--					</div>-->
					<div class="ys-my-product-text-cell">
						<div class="ys-my-product-text-cell-content">
							价格：{{ item.amount }}
						</div>
					</div>
				</div>
			</div>
<!--			<div class="ys-my-product-container-row-action-container">-->
<!--				<div v-if="!item.onSellInfo" class="ys-my-product-container-row-action" @click="onSellClick(item.productId, item.productCode)">指定</div>-->
<!--				<div v-if="item.onSellInfo" class="ys-my-product-container-row-action" @click="onCancelOnSell(item)">撤回指定</div>-->
<!--				<div class="ys-my-product-container-row-action">商品拆分</div>-->
<!--				<div class="ys-my-product-container-row-action" @click="onCloseProduct(item.productCode, item.productId)">封存</div>-->
<!--				<div class="ys-my-product-container-row-action">转场</div>-->
<!--			</div>-->
		</div>
	</div>
</template>

<script>
import {
	getMyProducts
} from "@/utils/methods";
export default {
	name: "MyProductView",
	computed: {
		myProductList () {
			return this.$store.state.myProducts
		}
	},
	async mounted() {
		const {err, result} = await getMyProducts()
		if (err === false && result?.msg) {
			this.$store.dispatch('setMyProductList', result.msg)
		}
	},
	methods: {
		getProductImageUrl(item) {
			return `https://qrcode.yssc365.com/${item.productId}.jpg`
		},
		getOwner () {
			return this.$store.state.userInfo.userName
		}
	}
}
</script>
<style scoped>
.ys-my-product-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 0.4em 0.4em 5em 0.4em;
	box-sizing: border-box;

}

.ys-my-product-container-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-bottom: 0.8em;
	background-color: #ffffff;
	box-sizing: border-box;
	align-items: center;
	border-bottom: 1px solid #e3e3e3;
}

.ys-my-product-image-container {
	max-width: 8em;
	max-height: 8em;
	margin-right: 0.8em;
}

.ys-my-product-text-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	flex: 1;
}

.ys-my-product-image {
	width: 100%;
}

.ys-my-product-text-cell {
	margin-bottom: 0.2em;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
}

.ys-my-product-text-cell-copy-btn {
	color: #FF8400;
	border: 1px solid #FF8400;
	padding: 0 0.4em;
	font-size: 1em;
	border-radius: 0.2em;
}

.ys-my-product-text-cell-content {
	flex: 1;
	text-align: start;
}

.ys-my-product-container-row-wrapper {
	display: flex;
	flex-direction: column;
	align-items: start;
	width: 100%;
	margin-bottom: 0.8em;
	padding: 0.8em;
	border-radius: 0.4em;
	box-sizing: border-box;
	background-color: #ffffff;

	overflow-y: auto;
}

.ys-my-product-container-row-action-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 创建两个等宽的列 */
	gap: 0.6em;
	margin-top: 0.6em;
	align-items: center;
	justify-content: center;
	padding: 0.4em;
	box-sizing: border-box;
}

.ys-my-product-container-row-action {
	color: dodgerblue;
	padding: 0.2em;
	border: 1px solid dodgerblue;
	border-radius: 0.2em;

}

.ys-my-product-input-on-sell-refer-code{
	margin: 1em 0;
}

.ys-my-product-cell-header{
	width: 100%;
	text-align: end;
	color: dodgerblue;
}
</style>