<template>
	<div class="ys-all-reservation-list-container">
		<div class="ys-all-reservation-card-container" v-for="item in this.reservationList" :key="item.reservationId">
			<div class="ys-all-reservation-card">
				<div class="ys-all-reservation-card-row">预约人： {{item.userName}}</div>
				<div class="ys-all-reservation-card-row">交易时间： {{item.reservationTime}}</div>
				<div class="ys-all-reservation-card-row">交易金额： {{item.amountRange}}</div>
				<div class="ys-all-reservation-card-row"><div>推荐码： {{item.referCode}}</div> <div class="ys-all-reservation-copy-refer-code-btn" @click="this.onCopyReferCodeClicked(item.referCode)">复制推荐码</div></div>
				<div class="ys-all-reservation-card-row">预约创建时间： {{item.createTimeString}}</div>
			</div>
		</div>
	</div>
</template>
<script>
import {getAllReservations} from "@/utils/methods";
import copy from "copy-to-clipboard";

export default {
	name: "AllReservationView",
	async mounted() {
		await this.getAllReservation()
	},
	computed: {
		reservationList (){
			return this.$store.state.allReservationList
		}
	},
	methods: {
		async getAllReservation() {
			const {err, result} = await getAllReservations()
			if (err === false && result?.code === 200) {

				this.$store.dispatch('setAllReservationList', result.msg)
			}
		},
		onCopyReferCodeClicked (code) {
			const copyResult = copy(code);
			if (copyResult === true) {
				this.$message.success({
					content: '复制成功',
					duration: 3 // 设置持续时间为3秒
				});
			} else {

				this.$message.error({
					content: '复制失败',
					duration: 3 // 设置持续时间为3秒
				});
			}
		}
	}
}

</script>
<style scoped>
.ys-all-reservation-list-container{
	width: 100%;
	height: 100%;
	padding: 0.4em;
	box-sizing: border-box;
	overflow-y: auto;
}

.ys-all-reservation-card-container{
	width: 100%;
}

.ys-all-reservation-card{
	background-color: #ffffff;
	padding: 0.6em;
	display: flex;
	flex-direction: column;
	align-items: start;
	border-radius: 0.2em;
	margin-bottom: 0.4em;
}

.ys-all-reservation-card-row{
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: space-between;
	margin: 0.2em 0;
}

.ys-all-reservation-copy-refer-code-btn{
	color: dodgerblue;
	padding: 0 0.4em;
	border: 1px solid dodgerblue;
	border-radius: 0.2em;
}
</style>