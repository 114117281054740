import axios from "axios";

const server = 'https://backend.yssc365.com:3000'

let isLoading = false

let loadingEle = null

const momentTz = require('moment-timezone');

const timezone = 'Asia/Shanghai'

let lastSessionIdVerifyTimestamp = null

let referMembersLastUpdateTimestamp = null

function getTitleTextByPageType(type) {
	if (type === 'home') {
		return '大宝天天见'
	}
	if (type === 'record') {
		return '大宝天天见'
	}
	if (type === 'account') {
		return '分销中心'
	}
	if (type === 'personal_center') {
		return '个人中心'
	}

	return '大宝天天见'
}

function getRouterPathByPageType(type) {
	if (type === 'home') {
		return '/main/home'
	}
	if (type === 'record') {
		return '/main/record'
	}
	if (type === 'account') {
		return '/main/accountCenter'
	}
	if (type === 'personal_center') {
		return '/main/accountInfo'
	}

	return '大宝天天见'
}

function getActivateMenuCodeByPath(path) {
	if (path === '/main/home') {
		return 'home'
	}
	if (path === '/main/record') {
		return 'record'
	}
	if (path === '/main/accountCenter') {
		return 'accountCenter'
	}
	if (path === '/main/accountInfo') {
		return 'accountInfo'
	}
	return 'home'
}

async function verifySessionId() {

	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		removeLocalUserInfo()
		return false
	}
	if (lastSessionIdVerifyTimestamp && getCurrentTimestamp() - lastSessionIdVerifyTimestamp < 30 * 60) {
		return true
	}
	const sessionExpireTimeStamp = localStorage.getItem('sessionExpireTimeStamp')
	if (!sessionExpireTimeStamp || parseInt(sessionExpireTimeStamp) < getCurrentTimestamp()) {
		removeLocalUserInfo()
		return true
	}
	showLoading()
	const result = await postRequestAsync('ys/users/verifySessionId', {sessionId: sessionId})
	hideLoading()

	if (result && result.code === 200) {
		lastSessionIdVerifyTimestamp = getCurrentTimestamp()
		return true
	}

	removeLocalUserInfo()
	return false
}

function getCurrentTimestamp() {
	return parseInt(momentTz().tz(timezone).format('X'))
}

async function postRequestAsync(url, data, retry = 3) {
	try {
		var myHeaders = new Headers();
		// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Accept", "*/*");
		// myHeaders.append("Host", "49.51.186.159");
		myHeaders.append("Connection", "keep-alive");
		const sessionId = localStorage.getItem('sessionId')
		if (sessionId) {
			myHeaders.append("sessionId", sessionId);
		}

		// Add cache-control headers to prevent caching
		myHeaders.append("Cache-Control", "no-cache");
		myHeaders.append("Pragma", "no-cache");
		myHeaders.append("Expires", "0");

		var raw = JSON.stringify(data);

		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow'
		};

		// const self = this

		const response = await fetch(`${server}/${url}`, requestOptions)
		return response.json()
	} catch (e) {
		if (retry < 0) {
			return null
		}
		await postRequestAsync(url, data, --retry)
	}

}

async function getRequestAsync(url, retry = 3) {

	try {
		const myHeaders = new Headers();
		// myHeaders.append("User-Agent", "Apifox/1.0.0 (https://www.apifox.cn)");
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Accept", "*/*");
		// myHeaders.append("Host", "124.220.215.2");
		myHeaders.append("Connection", "keep-alive");
		const sessionId = localStorage.getItem('sessionId')
		if (sessionId) {
			myHeaders.append("sessionId", sessionId);
		}

		// Add cache-control headers to prevent caching
		myHeaders.append("Cache-Control", "no-cache");
		myHeaders.append("Pragma", "no-cache");
		myHeaders.append("Expires", "0");

		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		// const self = this

		const response = await fetch(`${server}/${url}`, requestOptions)
		return response.json()
	} catch (e) {
		if (retry < 0) {
			return null
		}
		await getRequestAsync(url, --retry)
	}

}

function uploadFile(url, formData, uploadProgress, uploadSuccess, uploadFail) {
	showLoading()
	axios.post(`${server}/${url}`, formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		onUploadProgress: function (progressEvent) {
			// 计算上传进度的百分比
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
			uploadProgress(percentCompleted)
			// console.log(`Upload progress: ${percentCompleted}%`);
			// 你可以在这里根据百分比来更新UI，例如进度条或者状态信息
		}
	}).then(response => {
		uploadSuccess(response.data)
	})
		.catch(error => {
			uploadFail()
		}).finally(() => {
		hideLoading()
	});
}

function removeLocalUserInfo() {
	localStorage.removeItem('sessionId')
	localStorage.removeItem('sessionExpireTimeStamp')
}

function showLoading() {
	if (isLoading === false) {
		const loadingContainer = document.createElement("div")
		loadingContainer.classList.add('loader-container')
		let loadingBody = document.createElement("div")
		loadingBody.classList.add('loader');
		loadingContainer.append(loadingBody)
		// toastBody.innerText = text
		document.body.append(loadingContainer)
		isLoading = true
		loadingEle = loadingContainer
	}

}

function hideLoading() {
	if (loadingEle) {
		document.body.removeChild(loadingEle)
		isLoading = false
		loadingEle = null
	}
}

function isValidChineseMobile(mobile) {
	var pattern = /^1[3-9]\d{9}$/;
	return pattern.test(mobile);
}

async function sendRegisterVcode(phoneNum) {
	showLoading()
	const result = await postRequestAsync('ys/users/sendRegisterVcode', {phoneNum})
	hideLoading()

	return result
}

async function sendResetPasswordVcode(phoneNum) {
	showLoading()
	const result = await postRequestAsync('ys/users/sendResetPasswordVcode', {phoneNum})
	hideLoading()

	return result
}

async function resetPassword(phoneNum, vcode, password, shopCode) {
	showLoading()
	const result = await postRequestAsync('ys/users/setPassword', {phoneNum, vcode, password, shopCode})
	hideLoading()

	return result
}

async function createAccount(phoneNum, vcode, password, userName, referCode) {
	showLoading()
	const result = await postRequestAsync('ys/users/registerAccount', {phoneNum, vcode, password, userName, referCode})
	hideLoading()

	return result
}

async function userLogin(phoneNum, password, shopCode) {
	showLoading()
	const result = await postRequestAsync('ys/users/login', {phoneNum, password, shopCode})
	hideLoading()

	return result
}

async function getUserInfoBySessionId() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/users/getUserInfoBySessionId')
	hideLoading()
	return {err: false, result}
}

async function getPaymentCodeUrl() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/users/getPaymentCodeUrl')
	hideLoading()
	return {err: false, result}
}

async function getReferMember(isForce = false) {
	if (referMembersLastUpdateTimestamp && getCurrentTimestamp() - referMembersLastUpdateTimestamp < 30 * 60 && isForce !== true) {
		return {err: false, result: null}
	}
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/users/getReferMemberBySessionId')
	referMembersLastUpdateTimestamp = getCurrentTimestamp()
	hideLoading()
	return {err: false, result}
}

function uploadWechatPaymentCode(formData, updateProgressCb, success, fail) {
	uploadFile('ys/upload/uploadPaymentQrCode', formData, updateProgressCb, success, fail);
}

async function getAllActiveProducts() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/order/getAllActivateProducts')
	hideLoading()
	return {err: false, result}
}

async function getAllClosedProducts() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/order/getAllClosedProducts')
	hideLoading()
	return {err: false, result}
}

async function closeProduct(productId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/closeProduct', {productId})
	hideLoading()
	return {err: false, result}
}

async function activateProduct(productId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/activateProduct', {productId})
	hideLoading()
	return {err: false, result}
}

async function onSellProduct(productId, referCode) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/onSellProduct', {productId, referCode})
	hideLoading()
	return {err: false, result}
}

async function cancelOnSell(productId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/onCancelOnSell', {productId})
	hideLoading()
	return {err: false, result}
}

async function getAllOrders() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/order/getAllOrder')
	hideLoading()
	return {err: false, result}
}
async function getAllOrdersByPage(page) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/getAllOrderByPage', {page})
	hideLoading()
	return {err: false, result}
}


async function orderPaymentConfirm(orderId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/orderPaymentConfirm', {orderId})
	hideLoading()
	return {err: false, result}
}
async function orderConfirmProductSale(orderId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/orderConfirmProductSale', {orderId})
	hideLoading()
	return {err: false, result}
}

async function getMyProducts() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/order/getMyProducts')
	hideLoading()
	return {err: false, result}
}
async function getMyOrders() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/order/getMyOrder')
	hideLoading()
	return {err: false, result}
}
async function confirmOrderPayment(orderId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/confirmOrderPayment', {orderId})
	hideLoading()
	return {err: false, result}
}
async function confirmOrderBuyerPayment(orderId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/confirmOrderBuyerPayment', {orderId})
	hideLoading()
	return {err: false, result}
}

async function getFansByUserId (userId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/users/getReferToMemberByUserId', {userId})
	hideLoading()
	return {err: false, result}
}
async function makeReservation (time, amountRange) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/reservation/makeReservation', {time, amountRange})
	hideLoading()
	return {err: false, result}
}


async function getMyReservations() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/reservation/myReservation')
	hideLoading()
	return {err: false, result}
}

async function getAllReservations() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/reservation/allReservation')
	hideLoading()
	return {err: false, result}
}
async function getAllMemberList() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/users/getAllMember')
	hideLoading()
	return {err: false, result}
}
async function splitProduct (productId, amount) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/splitProduct', {productId, amount})
	hideLoading()
	return {err: false, result}
}

async function getMyDividends() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/dividend/getMyDividends')
	hideLoading()
	return {err: false, result}
}

async function requestDividend(recordIdList) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/dividend/requestWithdrawBalance', {dividendRecordIds: recordIdList})
	hideLoading()
	return {err: false, result}
}

async function checkIfDividendRequested() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/dividend/getRequestedDividend')
	hideLoading()
	return {err: false, result}
}

async function getWithdrawRequest() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/dividend/getAllWithdrawRequest')
	hideLoading()
	return {err: false, result}
}
async function clearWithdrawRequest(recordId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/dividend/clearWithdrawRequest', {recordId})
	hideLoading()
	return {err: false, result}
}
async function updateMemberName(memberName, memberId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/users/updateMemberName', {memberName, memberId})
	hideLoading()
	return {err: false, result}
}


async function getMyTeamOrders() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/order/getTeamOrders')
	hideLoading()
	return {err: false, result}
}
async function getAllOrderStats() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/order/getAllOrderStats')
	hideLoading()
	return {err: false, result}
}
async function getUnclearFee() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/order/getUnclearFee')
	hideLoading()
	return {err: false, result}
}
async function getUnclearFeeTest() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/order/getUnclearFeeTest')
	hideLoading()
	return {err: false, result}
}
async function confirmTechFeePayment(recordId, paidCode) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/confirmTechFeePayment', {recordId, paidCode})
	hideLoading()
	return {err: false, result}
}
async function confirmTechFeeReceived(recordId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/confirmTechFeePaymentReceived', {recordId})
	hideLoading()
	return {err: false, result}
}
async function getTecFeePaidCode(recordId) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/getPaidCode', {recordId})
	hideLoading()
	return {err: false, result}
}
async function getUnclearTechFeeCount() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/order/getUnclearTechFeeCount')
	hideLoading()
	return {err: false, result}
}
async function hasUnclearTechFee() {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await getRequestAsync('ys/order/hasUnclearTechFee')
	hideLoading()
	return {err: false, result}
}
async function getPaymentInfo(id) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/getPaymentInfo', {id})
	hideLoading()
	return {err: false, result}
}
async function getPaymentLink(recordId, paymentMethod) {
	const sessionId = localStorage.getItem('sessionId')
	if (!sessionId) {
		return {err: true}
	}

	showLoading()
	const result = await postRequestAsync('ys/order/getPaymentLink', {recordId, paymentMethod})
	hideLoading()
	return {err: false, result}
}
export {
	getTitleTextByPageType,
	getRouterPathByPageType,
	verifySessionId,
	getRequestAsync,
	postRequestAsync,
	isValidChineseMobile,
	sendRegisterVcode,
	createAccount,
	userLogin,
	sendResetPasswordVcode,
	resetPassword,
	getUserInfoBySessionId,
	getActivateMenuCodeByPath,
	getReferMember,
	uploadWechatPaymentCode,
	getPaymentCodeUrl,
	getAllActiveProducts,
	closeProduct,
	getAllClosedProducts,
	activateProduct,
	onSellProduct,
	cancelOnSell,
	getAllOrders,
	orderPaymentConfirm,
	orderConfirmProductSale,
	getMyProducts,
	getMyOrders,
	confirmOrderPayment,
	getFansByUserId,
	makeReservation,
	getMyReservations,
	getAllReservations,
	getAllMemberList,
	splitProduct,
	getMyDividends,
	requestDividend,
	checkIfDividendRequested,
	getWithdrawRequest,
	clearWithdrawRequest,
	confirmOrderBuyerPayment,
	updateMemberName,
	getMyTeamOrders,
	getAllOrdersByPage,
	getAllOrderStats,
	getUnclearFee,
	confirmTechFeePayment,
	confirmTechFeeReceived,
	getTecFeePaidCode,
	getUnclearTechFeeCount,
	hasUnclearTechFee,
	getPaymentInfo,
	getUnclearFeeTest,
	getPaymentLink
};