<template>

	<div class="ys-order-stats-container">
		<div class="ys-order-stats-stats-container">
			<div class="ys-order-stats-stats-row">
				{{ this.getToday }}
			</div>

			<div class="ys-order-stats-stats-row">
				<div class="ys-order-stats-stats-cell">创建数量：{{ this.getTodayCreatedCount }}</div>
				<div class="ys-order-stats-stats-cell">完成数量：{{ this.getTodayCompletedCount }}</div>
			</div>
			<div class="ys-order-stats-stats-row">
				<div class="ys-order-stats-stats-cell">创建金额：{{ this.getTodayCreatedAmount }}</div>
				<div class="ys-order-stats-stats-cell">完成金额：{{ this.getTodayCompletedAmount }}</div>
			</div>

		</div>

		<a-tabs v-model:activeKey="orderStateTabKey">
			<a-tab-pane key="allOrder" tab="全部订单">
				<div class="ys-order-stats-tab-container">
					<div v-for="item in this.myTeamAllOrders" :key="item.productId"
					     class="ys-order-stats-container-row-wrapper">
						<div class="ys-order-stats-cell-header">{{ this.getOrderStateText(item) }}</div>
						<div class="ys-order-stats-container-row">
							<div class="ys-order-stats-image-container">
								<img class="ys-order-stats-image" :src=getProductImageUrl(item) alt="商品图片">
							</div>
							<div class="ys-order-stats-text-container">
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content" style="font-weight: bold;">{{
											item.productCode
										}}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">卖家：{{ item.sellerUserName }}</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">买家：{{ item.buyerUserName }}</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										交易价格：{{ item.amount }}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										上架费：{{ this.getOnSalePrice(item) }}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										创建时间：{{ item.createTimeString }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-tab-pane>
			<a-tab-pane key="waitForPayment" tab="等待支付" force-render>

				<div class="ys-order-stats-tab-container">
					<div v-for="item in this.getTeamWaitForPaymentOrder" :key="item.productId"
					     class="ys-order-stats-container-row-wrapper">
						<div class="ys-order-stats-cell-header">{{ this.getOrderStateText(item) }}</div>
						<div class="ys-order-stats-container-row">
							<div class="ys-order-stats-image-container">
								<img class="ys-order-stats-image" :src=getProductImageUrl(item) alt="商品图片">
							</div>
							<div class="ys-order-stats-text-container">
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content" style="font-weight: bold;">{{
											item.productCode
										}}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">卖家：{{ item.sellerUserName }}</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">买家：{{ item.buyerUserName }}</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										交易价格：{{ item.amount }}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										上架费：{{ this.getOnSalePrice(item) }}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										创建时间：{{ item.createTimeString }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-tab-pane>
			<a-tab-pane key="waitForConfirm" tab="等待确认" force-render>

				<div class="ys-order-stats-tab-container">
					<div v-for="item in this.getWaitForPaymentConfirmOrder" :key="item.productId"
					     class="ys-order-stats-container-row-wrapper">
						<div class="ys-order-stats-cell-header">{{ this.getOrderStateText(item) }}</div>
						<div class="ys-order-stats-container-row">
							<div class="ys-order-stats-image-container">
								<img class="ys-order-stats-image" :src=getProductImageUrl(item) alt="商品图片">
							</div>
							<div class="ys-order-stats-text-container">
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content" style="font-weight: bold;">{{
											item.productCode
										}}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">卖家：{{ item.sellerUserName }}</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">买家：{{ item.buyerUserName }}</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										交易价格：{{ item.amount }}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										上架费：{{ this.getOnSalePrice(item) }}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										创建时间：{{ item.createTimeString }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-tab-pane>
			<a-tab-pane key="waitForSale" tab="等待上架" force-render>

				<div class="ys-order-stats-tab-container">
					<div v-for="item in this.getWaitForSaleOrder" :key="item.productId"
					     class="ys-order-stats-container-row-wrapper">
						<div class="ys-order-stats-cell-header">{{ this.getOrderStateText(item) }}</div>
						<div class="ys-order-stats-container-row">
							<div class="ys-order-stats-image-container">
								<img class="ys-order-stats-image" :src=getProductImageUrl(item) alt="商品图片">
							</div>
							<div class="ys-order-stats-text-container">
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content" style="font-weight: bold;">{{
											item.productCode
										}}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">卖家：{{ item.sellerUserName }}</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">买家：{{ item.buyerUserName }}</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										交易价格：{{ item.amount }}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										上架费：{{ this.getOnSalePrice(item) }}
									</div>
								</div>
								<div class="ys-order-stats-text-cell">
									<div class="ys-order-stats-text-cell-content">
										创建时间：{{ item.createTimeString }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-tab-pane>
		</a-tabs>

	</div>
</template>

<script>
import {
	getMyTeamOrders
} from "@/utils/methods";

export default {
	name: "OrderStatisticView",
	data() {
		return {
			orderStateTabKey: 'allOrder'
		}
	},
	computed: {
		getToday() {
			let today = new Date();
			return today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
		},
		myTeamAllOrders() {
			return this.$store.state.myTeamOrderList
		},
		getTeamWaitForPaymentOrder() {
			const toReturn = []
			for (const item of this.$store.state.myTeamOrderList) {
				if (item.orderState === 'wait_for_payment') {
					toReturn.push(item)
				}
			}
			return toReturn
		},
		getWaitForPaymentConfirmOrder() {
			const toReturn = []
			for (const item of this.$store.state.orderList) {
				if (item.orderState === 'wait_for_payment_confirm') {
					toReturn.push(item)
				}
			}
			return toReturn
		},
		getWaitForSaleOrder() {
			const toReturn = []
			for (const item of this.$store.state.orderList) {
				if (item.orderState === 'wait_for_sale') {
					toReturn.push(item)
				}
			}
			return toReturn
		},
		getTodayCompletedAmount() {
			let toReturn = 0
			for (const item of this.$store.state.myTeamOrderList) {
				if (item.orderState === 'completed' && item.isOldOrder !== true) {
					toReturn += parseFloat(item.amount)
				}
			}
			return toReturn.toFixed(2)
		},
		getTodayCreatedAmount() {
			let toReturn = 0
			for (const item of this.$store.state.myTeamOrderList) {
				if (item.isOldOrder !== true) {
					toReturn += parseFloat(item.amount)
				}

			}
			return toReturn.toFixed(2)
		},
		getTodayCompletedCount() {
			let toReturn = 0
			for (const item of this.$store.state.myTeamOrderList) {
				if (item.orderState === 'completed' && item.isOldOrder !== true) {
					toReturn += 1
				}
			}
			return toReturn
		},
		getTodayCreatedCount() {
			let toReturn = 0
			for (const item of this.$store.state.myTeamOrderList) {
				if (item.isOldOrder !== true) {
					toReturn += 1
				}

			}
			return toReturn
		}
	},
	async mounted() {
		await this.getTeamOrders()
	},
	methods: {
		async getTeamOrders() {
			const {err, result} = await getMyTeamOrders()
			if (err === false && result.code === 200) {
				this.$store.dispatch('setMyTeamOrderList', result.msg)
			}
		},
		getOrderStateText(item) {
			if (item.orderState === 'wait_for_payment') {
				return `已指派，等待买家支付`
			}
			if (item.orderState === 'wait_for_payment_confirm') {
				return '买家已支付，等待卖家确认'
			}
			if (item.orderState === 'wait_for_sale') {
				return '卖家已确认收款，等待上架'
			}
			if (item.orderState === 'completed') {
				return '商品已上架，订单完成'
			}
			if (item.orderState === 'order_closed') {
				return '订单已关闭'
			}
		},
		getProductImageUrl(item) {
			if (item.productImageUrl) {
				return item.productImageUrl
			}
			return `https://qrcode.yssc365.com/${item.productId}.jpg`
		},
		getOnSalePrice(item) {
			const amount = parseInt(item.amount)

			return (amount * 0.025).toFixed(2)
		},
	}
}
</script>
<style scoped>
.ys-order-stats-container {
	box-sizing: border-box;
	padding: 0.4em;
}

.ys-order-stats-tab-container {

}

.ys-order-stats-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 0.4em 0.4em 5em 0.4em;
	box-sizing: border-box;

}

.ys-order-stats-container-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-bottom: 0.8em;
	background-color: #ffffff;
	box-sizing: border-box;
	align-items: center;
}

.ys-order-stats-image-container {
	max-width: 8em;
	max-height: 8em;
	margin-right: 0.8em;
}

.ys-order-stats-text-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	flex: 1;
}

.ys-order-stats-image {
	width: 100%;
}

.ys-order-stats-text-cell {
	margin-bottom: 0.2em;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
}

.ys-order-stats-text-cell-copy-btn {
	color: #FF8400;
	border: 1px solid #FF8400;
	padding: 0 0.4em;
	font-size: 1em;
	border-radius: 0.2em;
}

.ys-order-stats-text-cell-content {
	flex: 1;
	text-align: start;
}

.ys-order-stats-container-row-wrapper {
	display: flex;
	flex-direction: column;
	align-items: start;
	width: 100%;
	margin-bottom: 0.8em;
	padding: 0.8em;
	border-radius: 0.4em;
	box-sizing: border-box;
	background-color: #ffffff;

	overflow-y: auto;
}

.ys-order-stats-container-row-action-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 创建两个等宽的列 */
	gap: 0.6em;
	margin-top: 0.6em;
	align-items: center;
	justify-content: center;
	padding: 0.4em;
	box-sizing: border-box;
}

.ys-order-stats-container-row-action {
	color: dodgerblue;
	padding: 0.2em;
	border: 1px solid dodgerblue;
	border-radius: 0.2em;

}

.ys-order-stats-input-on-sell-refer-code {
	margin: 1em 0;
}

.ys-order-stats-cell-header {
	width: 100%;
	text-align: end;
	color: dodgerblue;
}

.ys-order-stats-stats-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	color: dodgerblue;
	background-color: #ffffff;
	padding: 0.6em;
	border-radius: 0.4em;
	box-sizing: border-box;
}

.ys-order-stats-stats-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0.2em 0;
}

.ys-order-stats-stats-cell {
	flex: 1;
	text-align: start;
}
</style>