<template>
	<div class="ys-reset-password-page">
		<div class="ys-reset-password-label">重置密码</div>

		<a-input class="ys-reset-password-input" v-model:value="resetPhoneNum" placeholder="手机号" type="text"
		         size="large" :disabled="this.resetPhoneNumDisabled">
			<template #prefix>
				<img src="../assets/phone.svg" alt="phone">
			</template>
			<template #suffix>
				<div class="ys-reset-password-send-vcode-btn" @click="sendVCode">{{ this.getSendVcodeBtnText }}</div>
			</template>
		</a-input>


		<a-input class="ys-reset-password-input" v-model:value="resetVcode" placeholder="验证码" type="text"
		         size="large">
			<template #prefix>
				<img src="../assets/finger_print.svg" alt="phone">
			</template>
		</a-input>
		<a-input class="ys-reset-password-input" v-model:value="resetPassword" placeholder="新密码" type="password"
		         size="large">
			<template #prefix>
				<img src="../assets/password.svg" alt="phone">
			</template>
		</a-input>
		<a-input class="ys-reset-password-input" v-model:value="resetConfirmPassword" placeholder="确认新密码"
		         type="password" size="large">
			<template #prefix>
				<img src="../assets/password.svg" alt="phone">
			</template>
		</a-input>
		<a-input class="ys-reset-password-input" v-model:value="resetShopCode" placeholder="店号" type="text"
		         size="large">
			<template #prefix>
				<img src="../assets/shop.svg" alt="phone">
			</template>
		</a-input>
		<a-button type="primary" class="ys-reset-password-btn" @click="onSubmit" size="large">提交</a-button>
		<div class="ys-reset-password-login-btn" @click="onLogin">去登录</div>
	</div>
</template>

<script>
import {resetPassword, isValidChineseMobile, sendResetPasswordVcode} from "@/utils/methods";
import {message} from 'ant-design-vue';

export default {
	name: "ResetPasswordPage",
	props: ['phoneNum', 'shopCode'],
	// props: {
	// 	phoneNum: String
	// },
	data() {
		return {
			resetPhoneNum: '',
			resetPhoneNumDisabled: false,
			resetVcode: '',
			resetPassword: '',
			resetConfirmPassword: '',
			resetShopCode: '',
			sendVcodeCountdown: -1,
			sendVcodeInterval: null
		};
	},
	mounted() {
		if (this.phoneNum && isValidChineseMobile(this.phoneNum)) {
			this.resetPhoneNum = this.phoneNum;
			this.resetPhoneNumDisabled = true
		}

		if (this.shopCode) {
			this.resetShopCode = this.shopCode
		}
	},
	computed: {
		getSendVcodeBtnText() {
			if (this.sendVcodeCountdown < 0) {
				return '发送验证码'
			}
			return `${this.sendVcodeCountdown}秒后重新发送`

		}
	},
	methods: {
		async sendVCode() {

			if (this.sendVcodeInterval !== null) {
				return
			}

			if (!this.resetPhoneNum) {
				message.error('手机号不能为空');
				return
			}
			if (!isValidChineseMobile(this.resetPhoneNum)) {
				message.error('手机号码格式不正确');
				return
			}

			const result = await sendResetPasswordVcode(this.resetPhoneNum)

			if (result?.code === 201) {
				message.info('参数错误');
				return
			}

			if (result?.code === 202) {
				message.info('手机号不存在');
				return
			}

			if (result?.code === 203) {
				message.info('手机号格式不正确');
				return
			}

			if (result?.code === 200) {
				message.success('验证码发送成功');
				this.sendVcodeCountdown = 60
				this.sendVcodeInterval = setInterval(() => {
					this.sendVcodeCountdown--
					if (this.sendVcodeCountdown < 0) {
						clearInterval(this.sendVcodeInterval)
						this.sendVcodeInterval = null
					}
				}, 1000)
				return
			}

		},
		async submitRegister() {

		},
		async onSubmit() {
			if (!this.resetPhoneNum) {
				message.error('手机号不能为空');
				return
			}
			if (!isValidChineseMobile(this.resetPhoneNum)) {
				message.error('手机号码格式不正确');
				return
			}
			if (!this.resetVcode) {
				message.error('验证码不能为空');
				return
			}
			if (!this.resetPassword) {
				message.error('密码不能为空');
				return
			}
			if (!this.resetConfirmPassword) {
				message.error('密码确认不能为空');
				return
			}
			if (this.resetConfirmPassword !== this.resetPassword) {
				message.error('两次输入密码不一致');
				return
			}

			if (!this.resetShopCode) {
				message.error('店号不能为空');
				return
			}
			const result = await resetPassword(this.resetPhoneNum, this.resetVcode, this.resetPassword, this.resetShopCode)

			if (result?.code === 200) {
				message.success('密码重置成功');
				this.$router.push('/login')
				return
			}
			if (result?.code === 301) {
				message.error('验证码不存在');
				return
			}
			if (result?.code === 302) {
				message.error('验证码已过期');
				return
			}
			if (result?.code === 401) {
				message.error('用户不存在');
				return
			}
			if (result?.code === 402) {
				message.error('商户号不正确');
				return
			}
		},
		onLogin() {
			this.$router.push('/login')
		}
	}
}
</script>

<style scoped>
.ys-reset-password-page {
	width: 100%;
	height: 100%;
	background-color: #F4F5F6;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4em 2em 0 2em;
	box-sizing: border-box;
}

.ys-reset-password-label {
	margin-bottom: 1em;
	font-size: 1.6em;
}

.ys-reset-password-input {
	margin-bottom: 1em;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.ys-reset-password-send-vcode-btn {
	cursor: pointer;
}

.ys-reset-password-btn {
	background-color: #E5C7A1;
	width: 100%;
	margin-bottom: 1em;
}

.ys-reset-password-btn:hover {
	background-color: #b49366;
}

.ys-reset-password-login-btn {
	cursor: pointer;
	margin-bottom: 1em;
	text-decoration: underline;
}
</style>