<template>
	<div class="ys-make-reservation-view-container">
		<a-tabs v-model:activeKey="reservationTabKey" class="ys--make-reservation-tab-container">
			<a-tab-pane key="myResTab" tab="我要预约">
				<div class="ys-make-reservation-tab-content-container">
					<div class="ys-make-reservation-tab-content-row">预约时间：{{this.getReservationTime()}}</div>

					<div class="ys-make-reservation-amount-radio-container">
						<div class="ys-make-reservation-amount-radio-label">选择预约金额：</div>
						<div class="ys-make-reservation-amount-radio-row" @click="this.onAmountRadioClicked('2000-4000')">
							<img :src="this.getRadioImg('2000-4000')" alt="radio" class="ys-make-reservation-amount-radio">
							<div class="ys-make-reservation-amount-radio-row-text">2000-4000</div>
						</div>
						<div class="ys-make-reservation-amount-radio-row" @click="this.onAmountRadioClicked('4001-8000')">
							<img :src="this.getRadioImg('4001-8000')" alt="radio" class="ys-make-reservation-amount-radio">
							<div class="ys-make-reservation-amount-radio-row-text">4001-8000</div>
						</div>
					</div>

					<div class="ys-make-reservation-btn-row">
						<a-button class="ys-home-btn" type="primary" :size="'large'" @click="this.submitReservation()">
							提交
						</a-button>
					</div>

				</div>
			</a-tab-pane>
			<a-tab-pane key="resRecTab" tab="我的预约" force-render>

				<div class="ys-make-reservation-tab-content-container">

					<div v-for="item in this.myReservation" class="ys-my-reservation-row-container" :key="item.reservationId">
						<div>交易时间：{{item.reservationTime}}</div>
						<div>交易金额：{{item.amountRange}}</div>
						<div>创建时间：{{item.createTimeString}}</div>
					</div>
				</div>

			</a-tab-pane>
		</a-tabs>
	</div>
</template>
<script>
import radioEmpty from '@/assets/radio_empty.svg'
import radioFull from '@/assets/radio_full.svg'
import {makeReservation, getMyReservations} from "@/utils/methods";

export default {
	name: "ReservationView",
	data() {
		return {
			reservationTabKey: 'myResTab',
			selectedAmount: '2000-4000'
		}
	},
	async mounted() {
		await this.getMyReservations()
	},
	computed: {
		myReservation () {
			return this.$store.state.myReservationList
		}
	},
	methods: {
		async getMyReservations () {
			const {err, result} = await getMyReservations()
			if (err === false && result.code === 200) {
				this.$store.dispatch('setMyReservationList', result.msg)
			}
		},
		getReservationTime () {
			// 创建一个表示当前日期和时间的Date对象
			var today = new Date();

// 获取明天的日期
// setDate()的参数是今天的日期加上1
			today.setDate(today.getDate() + 1);

// 如果需要，可以将日期格式化为 yyyy-mm-dd
			return today.toISOString().split('T')[0];
		},
		onAmountRadioClicked(item) {
			this.selectedAmount = item
		},
		getRadioImg (item) {
			if (item === this.selectedAmount) {
				return radioFull
			}
			return radioEmpty
		},
		async submitReservation () {
			const {err, result} = await makeReservation(this.getReservationTime(), this.selectedAmount)

			if (err === false && result?.code === 200) {
				this.$message.success({
					content: '预约成功',
					duration: 3 // 设置持续时间为3秒
				});
				this.getMyReservations()
				return
			}
			if (err === false && result?.code === 203) {
				this.$message.info({
					content: '已预约',
					duration: 3 // 设置持续时间为3秒
				});
				this.getMyReservations()
				return
			}
			this.$message.error({
				content: '操作失败',
				duration: 3 // 设置持续时间为3秒
			});
		}
	}
}
</script>
<style scoped>
.ys-make-reservation-view-container {
	width: 100%;
	height: 100%;
}

.ys-make-reservation-tab-content-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0.6em;
	align-items: start;
	box-sizing: border-box;
}

.ys-make-reservation-tab-content-row{
	font-size: 1.2em;
}

.ys-make-reservation-amount-radio{
	width: 1.4em;
	margin-right: 0.4em;
}

.ys-make-reservation-amount-radio-container{
	padding: 0.8em 0;
}

.ys-make-reservation-amount-radio-label{
	font-size: 1.2em;
}

.ys-make-reservation-amount-radio-row{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 0.2em 0;
}
.ys-make-reservation-amount-radio-row-text{
	font-size: 1.4em;
}

.ys-make-reservation-btn-row{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2em;
}

.ys-my-reservation-row-container{
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: start;
	padding: 0.6em;
	border-radius: 0.4em;
	box-sizing: border-box;
	margin-bottom: 0.4em;
}
</style>