<template>
	<div class="ys-order-manage-container">
		<div class="ys-order-manage-stats-container">
			<div class="ys-order-manage-stats-row">
				{{this.getToday}}
			</div>

			<div class="ys-order-manage-stats-row">
				<div class="ys-order-manage-stats-cell">创建数量：{{ this.getAllOrderStats.orderCreateCount }}</div>
				<div class="ys-order-manage-stats-cell">完成数量：{{ this.getAllOrderStats.orderCompletedCount }}</div>
			</div>
			<div class="ys-order-manage-stats-row">
				<div class="ys-order-manage-stats-cell">创建金额：{{ this.getAllOrderStats.orderCreateAmount }}</div>
				<div class="ys-order-manage-stats-cell">完成金额：{{ this.getAllOrderStats.orderCompletedAmount }}</div>
			</div>

		</div>

		<a-tabs v-model:activeKey="orderStateTabKey">
			<a-tab-pane key="allOrder" tab="全部订单">
				<div class="ys-order-manage-tab-container">
					<div v-for="item in this.getAllOrder" :key="item.productId" class="ys-order-manage-container-wrapper">
						<div v-if="item.date !== null" class="ys-order-manage-date-container">
							{{item.date}}
						</div>
						<div
							class="ys-order-manage-container-row-wrapper">
							<div class="ys-order-manage-cell-header">{{ this.getOrderStateText(item) }}</div>
							<div class="ys-order-manage-container-row">
								<div class="ys-order-manage-image-container">
									<img class="ys-order-manage-image" :src=getProductImageUrl(item) alt="商品图片">
								</div>
								<div class="ys-order-manage-text-container">
									<div class="ys-order-manage-text-cell">
										<div class="ys-order-manage-text-cell-content" style="font-weight: bold;">{{
												item.productCode
											}}
										</div>
									</div>
									<div class="ys-order-manage-text-cell">
										<div class="ys-order-manage-text-cell-content">卖家：{{ item.sellerUserName }}</div>
									</div>
									<div class="ys-order-manage-text-cell">
										<div class="ys-order-manage-text-cell-content">买家：{{ item.buyerUserName }}</div>
									</div>
									<div class="ys-order-manage-text-cell">
										<div class="ys-order-manage-text-cell-content">
											交易价格：{{ item.amount }}
										</div>
									</div>
									<div class="ys-order-manage-text-cell">
										<div class="ys-order-manage-text-cell-content">
											上架费：{{ this.getOnSalePrice(item) }}
										</div>
									</div>
									<div class="ys-order-manage-text-cell">
										<div class="ys-order-manage-text-cell-content">
											创建时间：{{ item.createTimeString }}
										</div>
									</div>
								</div>
							</div>
							<div class="ys-order-manage-container-row-action-container"
							     v-if="this.canShowBtnContainer(item)">
								<div class="ys-order-manage-container-row-action" @click="this.confirmPayment(item)"
								     v-if="this.canShowConfirmPaymentBtn(item)">确认支付
								</div>
								<div class="ys-order-manage-container-row-action" @click="this.confirmProductSale(item)"
								     v-if="this.canShowConfirmProductSaleBtn(item)">商品上架
								</div>

							</div>
						</div>

						<div v-if="item.isLast === true && item.isAll !== true" class="ys-order-manage-date-container" @click="this.loadMoreOrderRecord()">
							加载更多
						</div>
						<div v-if="item.isAll === true" class="ys-order-manage-date-container">
							已加载全部
						</div>
					</div>
				</div>
			</a-tab-pane>
			<a-tab-pane key="waitForPayment" tab="等待支付" force-render>

				<div class="ys-order-manage-tab-container">
					<div v-for="item in this.getWaitForPaymentOrder" :key="item.productId"
					     class="ys-order-manage-container-row-wrapper">
						<div class="ys-order-manage-cell-header">{{ this.getOrderStateText(item) }}</div>
						<div class="ys-order-manage-container-row">
							<div class="ys-order-manage-image-container">
								<img class="ys-order-manage-image" :src=getProductImageUrl(item) alt="商品图片">
							</div>
							<div class="ys-order-manage-text-container">
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content" style="font-weight: bold;">{{
											item.productCode
										}}
									</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">卖家：{{ item.sellerUserName }}</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">买家：{{ item.buyerUserName }}</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">
										交易价格：{{ item.amount }}
									</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">
										上架费：{{ this.getOnSalePrice(item) }}
									</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">
										创建时间：{{ item.createTimeString }}
									</div>
								</div>
							</div>
						</div>
						<div class="ys-order-manage-container-row-action-container"
						     v-if="this.canShowBtnContainer(item)">
							<div class="ys-order-manage-container-row-action" @click="this.confirmPayment(item)"
							     v-if="this.canShowConfirmPaymentBtn(item)">确认支付
							</div>
							<div class="ys-order-manage-container-row-action" @click="this.confirmProductSale(item)"
							     v-if="this.canShowConfirmProductSaleBtn(item)">商品上架
							</div>

						</div>
					</div>
				</div>
			</a-tab-pane>
			<a-tab-pane key="waitForConfirm" tab="等待确认" force-render>

				<div class="ys-order-manage-tab-container">
					<div v-for="item in this.getWaitForPaymentConfirmOrder" :key="item.productId"
					     class="ys-order-manage-container-row-wrapper">
						<div class="ys-order-manage-cell-header">{{ this.getOrderStateText(item) }}</div>
						<div class="ys-order-manage-container-row">
							<div class="ys-order-manage-image-container">
								<img class="ys-order-manage-image" :src=getProductImageUrl(item) alt="商品图片">
							</div>
							<div class="ys-order-manage-text-container">
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content" style="font-weight: bold;">{{
											item.productCode
										}}
									</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">卖家：{{ item.sellerUserName }}</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">买家：{{ item.buyerUserName }}</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">
										交易价格：{{ item.amount }}
									</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">
										上架费：{{ this.getOnSalePrice(item) }}
									</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">
										创建时间：{{ item.createTimeString }}
									</div>
								</div>
							</div>
						</div>
						<div class="ys-order-manage-container-row-action-container"
						     v-if="this.canShowBtnContainer(item)">
							<div class="ys-order-manage-container-row-action" @click="this.confirmPayment(item)"
							     v-if="this.canShowConfirmPaymentBtn(item)">确认支付
							</div>
							<div class="ys-order-manage-container-row-action" @click="this.confirmProductSale(item)"
							     v-if="this.canShowConfirmProductSaleBtn(item)">商品上架
							</div>

						</div>
					</div>
				</div>
			</a-tab-pane>
			<a-tab-pane key="waitForSale" tab="等待上架" force-render>

				<div class="ys-order-manage-tab-container">
					<div v-for="item in this.getWaitForSaleOrder" :key="item.productId"
					     class="ys-order-manage-container-row-wrapper">
						<div class="ys-order-manage-cell-header">{{ this.getOrderStateText(item) }}</div>
						<div class="ys-order-manage-container-row">
							<div class="ys-order-manage-image-container">
								<img class="ys-order-manage-image" :src=getProductImageUrl(item) alt="商品图片">
							</div>
							<div class="ys-order-manage-text-container">
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content" style="font-weight: bold;">{{
											item.productCode
										}}
									</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">卖家：{{ item.sellerUserName }}</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">买家：{{ item.buyerUserName }}</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">
										交易价格：{{ item.amount }}
									</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">
										上架费：{{ this.getOnSalePrice(item) }}
									</div>
								</div>
								<div class="ys-order-manage-text-cell">
									<div class="ys-order-manage-text-cell-content">
										创建时间：{{ item.createTimeString }}
									</div>
								</div>
							</div>
						</div>
						<div class="ys-order-manage-container-row-action-container"
						     v-if="this.canShowBtnContainer(item)">
							<div class="ys-order-manage-container-row-action" @click="this.confirmPayment(item)"
							     v-if="this.canShowConfirmPaymentBtn(item)">确认支付
							</div>
							<div class="ys-order-manage-container-row-action" @click="this.confirmProductSale(item)"
							     v-if="this.canShowConfirmProductSaleBtn(item)">商品上架
							</div>

						</div>
					</div>
				</div>
			</a-tab-pane>
		</a-tabs>

	</div>
</template>

<script>
import {
	orderPaymentConfirm,
	orderConfirmProductSale,
	getAllOrdersByPage,
	getAllOrderStats
} from "@/utils/methods";
import {Modal} from "ant-design-vue";
import {createVNode} from "vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
const momentTz = require('moment-timezone');


export default {
	name: "OrderManageView",
	data() {
		return {
			orderStateTabKey: 'allOrder',
			page: 1
		}
	},
	async mounted() {
		await this.updateAllOrders()
		await this.updateAllOrderStats()
	},
	computed: {
		getAllOrder() {
			const toReturn = []
			let last = null
			for (const item of this.$store.state.orderList) {
				item.isLast = this.$store.state.orderList.indexOf(item) === this.$store.state.orderList.length - 1;
				if (last === null) {
					item.date = momentTz(item.createTimestamp * 1000).tz('Asia/Shanghai').format('YYYY-MM-DD')
					last = item.date
					toReturn.push(item)
				} else {
					const date = momentTz(item.createTimestamp * 1000).tz('Asia/Shanghai').format('YYYY-MM-DD')
					if (date !== last) {
						item.date = date
					} else {
						item.date = null
					}
					last = date
					toReturn.push(item)
				}

			}
			return toReturn
		},
		getAllOrderStats() {
			return this.$store.state.allOrderStats
		},
		getWaitForPaymentOrder() {
			const toReturn = []
			for (const item of this.$store.state.orderList) {
				if (item.orderState === 'wait_for_payment') {
					toReturn.push(item)
				}
			}
			return toReturn
		},
		getWaitForPaymentConfirmOrder() {
			const toReturn = []
			for (const item of this.$store.state.orderList) {
				if (item.orderState === 'wait_for_payment_confirm') {
					toReturn.push(item)
				}
			}
			return toReturn
		},
		getWaitForSaleOrder() {
			const toReturn = []
			for (const item of this.$store.state.orderList) {
				if (item.orderState === 'wait_for_sale') {
					toReturn.push(item)
				}
			}
			return toReturn
		},
		getToday () {
			let today = new Date();
			return today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');
		}
	},
	methods: {
		async updateAllOrders() {
			this.page = 1
			const {err, result} = await getAllOrdersByPage(this.page)
			if (err === false && result?.code === 200) {
				this.$store.dispatch('setOrderList', result.msg.sort((a, b) => {
					return b.createTimestamp - a.createTimestamp
				}))
			}
		},
		getOrderStateText(item) {
			if (item.orderState === 'wait_for_payment') {
				return `已指派，等待买家支付`
			}
			if (item.orderState === 'wait_for_payment_confirm') {
				return '买家已支付，等待卖家确认'
			}
			if (item.orderState === 'wait_for_sale') {
				return '卖家已确认收款，等待上架'
			}
			if (item.orderState === 'completed') {
				return '商品已上架，订单完成'
			}
			if (item.orderState === 'order_closed') {
				return '订单已关闭'
			}
		},
		getProductImageUrl(item) {
			if (item.productImageUrl) {
				return item.productImageUrl
			}
			return `https://qrcode.yssc365.com/${item.productId}.jpg`
		},
		async updateAllOrderStats () {
			const {err, result} = await getAllOrderStats()
			if (err === false && result?.code === 200) {
				this.$store.dispatch('setAllOrderStats', result.msg)
			}
		},
		async confirmPayment(item) {
			Modal.confirm({
				content: `确认订单已支付？`,
				icon: createVNode(ExclamationCircleOutlined),
				onOk: async function () {


					let {err, result} = await orderPaymentConfirm(item.orderId)
					if (err === true) {
						this.$message.error({
							content: '操作失败',
							duration: 3 // 设置持续时间为3秒
						});
						return
					}
					if (err === false) {
						if (result?.code === 200) {
							this.$message.success({
								content: '操作成功',
								duration: 3 // 设置持续时间为3秒
							});
							await this.updateAllOrders()
						}
					}

				}.bind(this),
				cancelText: '取消',
				okText: '确认',
				onCancel() {
					Modal.destroyAll();
				}
			});

		},
		confirmProductSale(item) {
			Modal.confirm({
				content: `确认商品上架？`,
				icon: createVNode(ExclamationCircleOutlined),
				onOk: async function () {


					let {err, result} = await orderConfirmProductSale(item.orderId)
					if (err === true) {
						this.$message.error({
							content: '操作失败',
							duration: 3 // 设置持续时间为3秒
						});
						return
					}
					if (err === false) {
						if (result?.code === 200) {
							this.$message.success({
								content: '操作成功',
								duration: 3 // 设置持续时间为3秒
							});
							await this.updateAllOrders()
							return
						}
					}

				}.bind(this),
				cancelText: '取消',
				okText: '确认',
				onCancel() {
					Modal.destroyAll();
				}
			});
		},
		canShowConfirmPaymentBtn(item) {
			return item?.orderState === 'wait_for_payment' || item?.orderState === 'wait_for_payment_confirm'
		},
		canShowConfirmProductSaleBtn(item) {
			return item?.orderState === 'wait_for_sale'
		},
		getOnSalePrice(item) {
			const amount = parseInt(item.amount)

			return (amount * 0.025).toFixed(2)
		},
		canShowBtnContainer(item) {
			return !(item.orderState === 'order_closed' || item.orderState === 'completed');

		},
		async loadMoreOrderRecord() {
			this.page++
			const {err, result} = await getAllOrdersByPage(this.page)
			if (err === false && result?.code === 200) {
				if (result.msg.length === 0) {
					this.$message.info({
						content: '已全部加载',
						duration: 3 // 设置持续时间为3秒
					});
					this.$store.dispatch('setLastOrderList')
					return
				}
				this.$store.dispatch('appendOrderList', result.msg.sort((a, b) => {
					return b.createTimestamp - a.createTimestamp
				}))
				if (result.msg.length < 20) {
					this.$store.dispatch('setLastOrderList')
				}
			}
		}
	}
}
</script>

<style scoped>
.ys-order-manage-container {
	box-sizing: border-box;
	padding: 0.4em;
}

.ys-order-manage-tab-container {

}

.ys-order-manage-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 0.4em 0.4em 5em 0.4em;
	box-sizing: border-box;

}

.ys-order-manage-container-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-bottom: 0.8em;
	background-color: #ffffff;
	box-sizing: border-box;
	align-items: center;
}

.ys-order-manage-image-container {
	max-width: 8em;
	max-height: 8em;
	margin-right: 0.8em;
}

.ys-order-manage-text-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	flex: 1;
}

.ys-order-manage-image {
	width: 100%;
}

.ys-order-manage-text-cell {
	margin-bottom: 0.2em;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
}

.ys-order-manage-text-cell-copy-btn {
	color: #FF8400;
	border: 1px solid #FF8400;
	padding: 0 0.4em;
	font-size: 1em;
	border-radius: 0.2em;
}

.ys-order-manage-text-cell-content {
	flex: 1;
	text-align: start;
}

.ys-order-manage-container-row-wrapper {
	display: flex;
	flex-direction: column;
	align-items: start;
	width: 100%;
	margin-bottom: 0.8em;
	padding: 0.8em;
	border-radius: 0.4em;
	box-sizing: border-box;
	background-color: #ffffff;

	overflow-y: auto;
}

.ys-order-manage-container-row-action-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 创建两个等宽的列 */
	gap: 0.6em;
	margin-top: 0.6em;
	align-items: center;
	justify-content: center;
	padding: 0.4em;
	box-sizing: border-box;
	border-top: 1px solid #e3e3e3;
}

.ys-order-manage-container-row-action {
	color: dodgerblue;
	padding: 0.2em;
	border: 1px solid dodgerblue;
	border-radius: 0.2em;

}

.ys-order-manage-input-on-sell-refer-code {
	margin: 1em 0;
}

.ys-order-manage-cell-header {
	width: 100%;
	text-align: end;
	color: dodgerblue;
}

.ys-order-manage-stats-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	color: dodgerblue;
	background-color: #ffffff;
	padding: 0.6em;
	border-radius: 0.4em;
	box-sizing: border-box;
}

.ys-order-manage-stats-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0.2em 0;
}

.ys-order-manage-stats-cell {
	flex: 1;
	text-align: start;
}

.ys-order-manage-date-container{
	width: 100%;
	text-align: center;
	padding-bottom: 0.2em;
	color: #919191;
	font-size: 1.2em;
}
</style>