<template>
	<div class="ys-my-fans-container">

		<a-modal v-model:open="openMemberEditor" :title="'编辑会员信息'">
			<template #footer>
				<a-button key="submit" @click="cancelMemberEdit">取消</a-button>
				<a-button key="back" type="primary" @click="confirmMemberEdit">确定</a-button>
			</template>
			<div class="ys-my-fans-modal-label">会员姓名</div>
			<a-input v-model:value="editMemberName" size="large" type="text" placeholder="在此输入会员姓名"/>
		</a-modal>
		<div v-for="item in this.getReferMemberList" class="ys-my-fans-row-container" :key="item.userId">
			<div class="ys-my-fans-row">
				<div class="ys-my-fans-text-container">
					<div class="ys-my-fans-cell">
						<div>
							{{ item.userName }}
						</div>
						<img src="../assets/edit.svg" alt="编辑" class="ys-my-fans-cell-icon" @click="this.onEditMember(item)">
					</div>
					<div class="ys-my-fans-cell">{{ item.phoneNum }}</div>
					<div class="ys-my-fans-cell">推荐码：{{ item.referCode }}</div>
					<div class="ys-my-fans-cell">粉丝数：{{ item.referMember }}</div>
					<div class="ys-my-fans-cell">团队交易额：{{ item.teamAmount }}</div>
					<div class="ys-my-fans-cell">等级：{{ item.level }}</div>
				</div>
				<div v-if="item.referMember > 0" class="ys-my-fans-row-extra" @click="this.onShowFans(item)">
					<div class="ys-my-fans-row-extra-text">{{ this.getShowFansText(item) }}</div>
					<img class="ys-my-fans-row-arrow" :src=this.getShowFansIcon(item) alt="right arrow">
				</div>
			</div>
			<div v-if="item.showFans" class="ys-my-fans-row-fans-list-container">
				<div v-for="fans in item.fansList" :key="fans.userId" class="ys-my-fans-row-fans-list-row">
					<div>{{ fans.userName }}</div>
					<div>{{ fans.phoneNum }}</div>
					<div>推荐码：{{ fans.referCode }}</div>
					<div>粉丝数：{{ fans.referMember }}</div>
					<div class="ys-my-fans-cell">团队交易额：{{ fans.teamAmount }}</div>
					<div>等级：{{ fans.level }}</div>
				</div>
			</div>

		</div>
	</div>

</template>

<script>
import eyeOpen from '@/assets/eye_open.svg'
import eyeClosed from '@/assets/eye_closed.svg'
import {getFansByUserId, getReferMember, updateMemberName} from "@/utils/methods";

export default {
	name: "MyFansView",
	data() {
		return {
			openMemberEditor: false,
			editMemberName: null,
			editMemberId: null
		}
	},
	computed: {
		getReferMemberList() {
			return this.$store.state.referMemberList
		}
	},
	methods: {
		async onShowFans(item) {
			item.showFans = !item.showFans
			if (item.showFans === true) {
				await this.getFans(item)
			}
		},
		getShowFansText(item) {
			if (item.showFans === true) {
				return '隐藏粉丝'
			}
			return '显示粉丝'
		},
		getShowFansIcon(item) {
			if (item.showFans === true) {
				return eyeClosed
			}
			return eyeOpen
		},
		async getFans(item) {
			const {err, result} = await getFansByUserId(item.userId)
			if (err === false && result?.code === 200) {
				item.fansList = result?.msg
			}
		},
		async confirmMemberEdit() {
			if (!this.editMemberName){
				this.$message.error({
					content: '会员名不能为空',
					duration: 3 // 设置持续时间为3秒
				});
				return
			}
			const {err, result} = await updateMemberName(this.editMemberName, this.editMemberId)
			if (err === false && result?.code === 200) {
				this.$message.success({
					content: '操作成功',
					duration: 3 // 设置持续时间为3秒
				});
				await this.updateFansList()
				this.openMemberEditor = false
			}
		},
		cancelMemberEdit() {

			this.openMemberEditor = false
		},
		onEditMember(item) {
			this.editMemberName = item.userName
			this.editMemberId = item.userId
			this.openMemberEditor = true
		},
		async updateFansList () {
			const {err, result} = await getReferMember(true)
			if (err === false && result?.code === 200 && Array.isArray(result?.msg?.referMemberList)) {
				this.$store.dispatch('setReferMemberList', result?.msg?.referMemberList)
			}
		}
	}
}
</script>
<style scoped>
.ys-my-fans-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	padding: 0.4em 0.4em 12em 0.4em;
	box-sizing: border-box;

}

.ys-my-fans-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.ys-my-fans-cell {
	margin: 0.2em 0;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.ys-my-fans-text-container {
	display: flex;
	flex-direction: column;
	align-items: start;
}

.ys-my-fans-row-arrow {
	width: 1.4em;
}

.ys-my-fans-row-extra {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.ys-my-fans-row-extra-text {
	color: dodgerblue;
	margin-right: 0.4em;
}

.ys-my-fans-row-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0.4em 0;
	padding: 0.4em 0.8em;
	border-radius: 0.2em;
	background-color: #ffff;
	box-sizing: border-box;
}

.ys-my-fans-row-fans-list-container {
	margin-top: 1em;
	padding: 0.4em 0;
	border-top: 1px solid #e1e1e1;
	align-items: start;
	display: grid;
	gap: 0.6em;
	grid-template-columns: 1fr 1fr; /* 创建两个等宽的列 */
	box-sizing: border-box;
}

.ys-my-fans-row-fans-list-row {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: start;
	border: 1px solid #e0e0e0;
	background-color: #e0e0e0;
	padding: 0.4em;
	box-sizing: border-box;
	border-radius: 0.2em;
}
.ys-my-fans-cell-icon{
	height: 1.2em;
	padding: 0 0.4em;
}

.ys-my-fans-modal-label{
	font-size: 1.2em;
	margin-bottom: 0.4em;
}
</style>