<template>
	<div class="ys-register-page">
		<div class="ys-register-label">用户注册</div>

		<a-input class="ys-register-input" v-model:value="regPhoneNum" placeholder="手机号" type="text" size="large">
			<template #prefix>
				<img src="../assets/phone.svg" alt="phone">
			</template>
			<template #suffix>
				<div class="ys-register-send-vcode-btn" @click="sendVCode">{{ this.getSendVcodeBtnText }}</div>
			</template>
		</a-input>


		<a-input class="ys-register-input" v-model:value="regVcode" placeholder="验证码" type="text" size="large">
			<template #prefix>
				<img src="../assets/finger_print.svg" alt="phone">
			</template>
		</a-input>
		<a-input class="ys-register-input" v-model:value="regUserName" placeholder="用户姓名" type="text" size="large">
			<template #prefix>
				<img src="../assets/user.svg" alt="用户姓名">
			</template>
		</a-input>
		<a-input class="ys-register-input" v-model:value="regPassword" placeholder="登录密码" type="password" size="large">
			<template #prefix>
				<img src="../assets/password.svg" alt="phone">
			</template>
		</a-input>
		<a-input class="ys-register-input" v-model:value="regConfirmPassword" placeholder="确认登录密码" type="password" size="large">
			<template #prefix>
				<img src="../assets/password.svg" alt="phone">
			</template>
		</a-input>
		<a-input class="ys-register-input" v-model:value="regReferCode" placeholder="推荐码" type="password" size="large">
			<template #prefix>
				<img src="../assets/refer_code.svg" alt="phone">
			</template>
		</a-input>
<!--		<a-input class="ys-register-input" v-model:value="regShopCode" placeholder="店号" type="text" size="large">-->
<!--			<template #prefix>-->
<!--				<img src="../assets/shop.svg" alt="phone">-->
<!--			</template>-->
<!--		</a-input>-->
		<a-button type="primary" class="ys-register-btn" @click="onSubmit" size="large">提交</a-button>
		<div class="ys-register-login-btn" @click="onLogin">我有账号，直接登录</div>
	</div>
</template>

<script>
import {createAccount, isValidChineseMobile, sendRegisterVcode} from "@/utils/methods";
import { message } from 'ant-design-vue';
export default {
	name: "RegisterPage",
	data() {
		return {
			regPhoneNum: '',
			regVcode: '',
			regUserName: '',
			regPassword: '',
			regConfirmPassword: '',
			regReferCode: '',
			sendVcodeCountdown: -1,
			sendVcodeInterval: null
		};
	},
	computed: {
		getSendVcodeBtnText () {
			if (this.sendVcodeCountdown < 0) {
				return '发送验证码'
			}
			return `${this.sendVcodeCountdown}秒后重新发送`

		}
	},
	methods: {
		async sendVCode () {

			if (this.sendVcodeInterval !== null) {
				return
			}

			if (!this.regPhoneNum) {
				message.error('手机号不能为空');
				return
			}
			if (!isValidChineseMobile(this.regPhoneNum)) {
				message.error('手机号码格式不正确');
				return
			}

			const result = await sendRegisterVcode(this.regPhoneNum)

			if (result?.code === 202) {
				message.info('该手机号已注册，请直接登录');
				return
			}
			if (result?.code === 200) {
				message.success('验证码发送成功');
				this.sendVcodeCountdown = 60
				this.sendVcodeInterval = setInterval(() => {
					this.sendVcodeCountdown--
					if (this.sendVcodeCountdown < 0) {
						clearInterval(this.sendVcodeInterval)
						this.sendVcodeInterval = null
					}
				}, 1000)
				return
			}

		},
		async submitRegister () {

		},
		async onSubmit() {
			if (!this.regPhoneNum) {
				message.error('手机号不能为空');
				return
			}
			if (!isValidChineseMobile(this.regPhoneNum)) {
				message.error('手机号码格式不正确');
				return
			}
			if (!this.regVcode) {
				message.error('验证码不能为空');
				return
			}
			if (!this.regUserName) {
				message.error('用户姓名不能为空');
				return
			}
			if (!this.regPassword) {
				message.error('密码不能为空');
				return
			}
			if (!this.regConfirmPassword) {
				message.error('密码确认不能为空');
				return
			}
			if (this.regConfirmPassword !== this.regPassword) {
				message.error('两次输入密码不一致');
				return
			}

			if(!this.regReferCode) {
				message.error('推荐码不能为空');
				return
			}
			// if(!this.regShopCode) {
			// 	message.error('店号不能为空');
			// 	return
			// }
			const result = await createAccount(this.regPhoneNum, this.regVcode, this.regPassword, this.regUserName, this.regReferCode)

			if (result?.code === 200) {

				message.success('注册成功');
				const sessionId = result?.msg?.sessionId
				const sessionExpireTimeStamp = result?.msg?.sessionExpireTimeStamp
				localStorage.setItem('sessionId', sessionId)
				localStorage.setItem('sessionExpireTimeStamp', sessionExpireTimeStamp)
				this.$router.push('/main')
				return
			}
			if (result?.code === 301) {
				message.success('验证码不存在');
				return
			}
			if (result?.code === 302) {
				message.success('验证码已过期');
				return
			}
			if (result?.code === 401) {
				message.success('用户已存在，请直接登录');
				return
			}
		},
		onLogin() {
			this.$router.push('/login')
		}
	}
}
</script>

<style scoped>
.ys-register-page {
	width: 100%;
	height: 100%;
	background-color: #F4F5F6;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4em 2em 0 2em;
	box-sizing: border-box;
}

.ys-register-label {
	margin-bottom: 1em;
	font-size: 1.6em;
}

.ys-register-input {
	margin-bottom: 1em;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.ys-register-send-vcode-btn {
	cursor: pointer;
}

.ys-register-btn {
	background-color: #E5C7A1;
	width: 100%;
	margin-bottom: 1em;
}
.ys-register-btn:hover {
	background-color: #b49366;
}

.ys-register-login-btn{
	cursor: pointer;
	margin-bottom: 1em;
	text-decoration: underline;
}
</style>