<template>
	<div class="ys-withdraw-request-container">
		<div class="ys-withdraw-request-card-container" v-for="item in this.withdrawRequestList" :key="item.recordId">
			<div class="ys-withdraw-request-card-state-row" :style="getWithdrawCardStateCard(item)">{{ this.getWithdrawStateText(item) }}</div>
			<div>申请人：{{ item.requestUserName }}</div>
			<div>提现时间：{{ item.requestTimeString }}</div>
			<div>提现金额：{{ item.amount }}</div>
			<div class="ys-withdraw-request-card-btn-row" v-if="this.canShowClearWithdrawRequestBtn(item)">
				<div class="ys-withdraw-request-card-btn" @click="this.clearWithdrawRequest(item)">申请已结算</div>
			</div>
		</div>
	</div>
</template>
<script>

import {
	getWithdrawRequest,
	clearWithdrawRequest,
	closeProduct,
	getAllClosedProducts,
	getAllActiveProducts
} from "@/utils/methods";
import {Modal} from "ant-design-vue";
import {createVNode} from "vue";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";

export default {
	name: "WithdrawRequestView",
	async mounted() {
		await this.getAllWithdrawRequests()
	},
	computed: {
		withdrawRequestList() {
			return this.$store.state.withdrawRequestList
		}
	},
	methods: {
		async getAllWithdrawRequests () {
			const {err, result} = await getWithdrawRequest()
			if (err === false && result.code === 200) {
				this.$store.dispatch('setWithdrawRequestList', result.msg)
			}
		},
		getWithdrawStateText(item) {
			if (item.isClear === true) {
				return '已结算'
			}
			return '未结算'
		},
		getWithdrawCardStateCard(item) {
			if (item.isClear === true) {
				return {
					color: 'green'
				}
			}
			return {
				color: 'red'
			}
		},
		canShowClearWithdrawRequestBtn (item) {
			if (item.isClear === true) {
				return false
			}
			return true
		},
		async clearWithdrawRequest (item) {
			Modal.confirm({
				content: `确认该申请已结算 ？`,
				icon: createVNode(ExclamationCircleOutlined),
				onOk: async function () {
					let {err, result} = await clearWithdrawRequest(item.recordId)
					if (err === false && result?.code === 200) {
						this.$message.success({
							content: '操作成功',
							duration: 3 // 设置持续时间为3秒
						});
						await this.getAllWithdrawRequests()
					} else {
						this.$message.error({
							content: '操作失败',
							duration: 3 // 设置持续时间为3秒
						});
					}
				}.bind(this),
				cancelText: '取消',
				okText: '确认',
				onCancel() {
					Modal.destroyAll();
				}
			});
		}
	}
}
</script>
<style scoped>
.ys-withdraw-request-container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	padding: 0.4em;
}

.ys-withdraw-request-card-container {
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: start;
	padding: 0.6em;
	border-radius: 0.4em;
	margin-bottom: 0.4em;
}

.ys-withdraw-request-card-state-row{
	width: 100%;
	text-align: end;
}

.ys-withdraw-request-card-btn-row{
	width: 100%;
	display: flex;
	flex-direction: row;
	border-top: 1px solid #dedede;
	padding-top: 0.4em;
	margin-top: 0.4em;
}

.ys-withdraw-request-card-btn{
	color: dodgerblue;
	padding: 0 0.8em;
	border: 1px solid dodgerblue;
	border-radius: 0.2em;
}
</style>