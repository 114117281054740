<template>
	<div class="ys-home-container">
		<div class="ys-home-bg-image" v-if="this.canShowHomeView()"/>
		<img class="ys-home-bg-image-corner-tl" src="../assets/bg_corner.svg" alt="bg corner" v-if="this.canShowHomeView()">
		<img class="ys-home-bg-image-corner-tr" src="../assets/bg_corner.svg" alt="bg corner" v-if="this.canShowHomeView()">
		<img class="ys-home-bg-image-corner-bl" src="../assets/bg_corner.svg" alt="bg corner" v-if="this.canShowHomeView()">
		<img class="ys-home-bg-image-corner-br" src="../assets/bg_corner.svg" alt="bg corner" v-if="this.canShowHomeView()">
		<img class="ys-home-bg-image-cloud1" src="../assets/bg_cloud1.svg" alt="bg corner" v-if="this.canShowHomeView()">
		<img class="ys-home-bg-image-cloud2" src="../assets/bg_cloud2.svg" alt="bg corner" v-if="this.canShowHomeView()">
		<img class="ys-home-bg-image-cloud3" src="../assets/bg_cloud3.svg" alt="bg corner" v-if="this.canShowHomeView()">
		<router-view v-if="!this.canShowHomeView()"></router-view>
		<div class="ys-home-btn-container" v-if="this.canShowHomeView()">
			<div class="ys-home-btn" @click="onMakeReservation">预约接龙</div>
			<div class="ys-home-btn" @click="onCheckReservationList">预约列表</div>
<!--			<a-button class="ys-home-btn" type="primary" :size="'large'" @click="onMakeReservation">-->
<!--				预约接龙-->
<!--			</a-button>-->
<!--			<a-button class="ys-home-btn" type="primary" :size="'large'" @click="onCheckReservationList">-->

<!--				预约列表-->
<!--			</a-button>-->
		</div>
	</div>
</template>

<script>
export default {
	name: "AccountInfoView",
	methods: {
		onMakeReservation() {
			this.$router.push({name: 'reservation'})
		},
		canShowHomeView() {
			return this.$route.name === 'home'
		},
		onCheckReservationList() {
			this.$router.push({name: 'reservationList'})
		}
	}
}
</script>
<style scoped>
.ys-home-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
}

.ys-home-bg-image {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #B0010C;
}

.ys-home-bg-image-corner-tl {
	width: 6em;
	position: absolute;
	top: 0;
	left: 0;
}

.ys-home-bg-image-corner-tr {
	width: 6em;
	position: absolute;
	top: 0;
	right: 0;
	transform: scaleX(-1);
}

.ys-home-bg-image-corner-bl {
	width: 6em;
	position: absolute;
	bottom: 0;
	left: 0;
	transform: scaleY(-1);
}

.ys-home-bg-image-corner-br {
	width: 6em;
	position: absolute;
	bottom: 0;
	right: 0;
	transform: scale(-1, -1);
}

.ys-home-bg-image-cloud1 {
	width: 12em;
	position: absolute;
	bottom: 10em;
	left: 2em;
}
.ys-home-bg-image-cloud2 {
	width: 8em;
	position: absolute;
	top: 10em;
	right: 3em;
	transform: scaleX(-1);
}
.ys-home-bg-image-cloud3 {
	width: 8em;
	position: absolute;
	bottom: 10em;
	right: 1em;
}

.ys-home-btn-container {
	display: flex;
	flex-direction: column;
	width: 60%;
}

.ys-home-btn {
	margin: 0.4em 0;
	background: rgb(255,176,98);
	background: linear-gradient(30deg, rgba(255,176,98,1) 0%, rgba(255,187,118,1) 49%, rgba(255,223,187,1) 100%);
	color: #850008 !important;
	z-index: 10;
	padding: 0.4em 0;
	border-radius: 0.4em;
	cursor: pointer;
	font-size: 1.2em;
}
</style>