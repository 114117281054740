<template>
	<div class="ys-account-center-view-container">
		<router-view v-if="this.isShowRouterView()"></router-view>
		<div  v-if="!this.isShowRouterView()" class="ys-account-center-view-wrapper">
			<div class="ys-account-name-container-bg"></div>
			<div class="ys-account-name-container">
				<div class="ys-account-name-label">推荐人：{{this.getReferenceInfo}}</div>
				<div class="ys-account-name-label-1">{{ this.getShopName }}</div>
			</div>
			<div class="ys-account-data-container">
				<div class="ys-account-my-price">
					<div class="ys-account-my-price-row">
						<div class="ys-account-my-prize-label">
							<img src="../assets/prize.svg" alt="prize" class="ys-account-my-prize-img">
							<div>我的奖金</div>
						</div>
						<div class="ys-account-withdraw-btn">
							<div>奖金提现</div>
							<img src="../assets/right_arrow.svg" alt="right arrow"></div>
					</div>
					<div class="ys-account-my-price-row-1">
						<div class="ys-account-withdraw-container">
							<div class="ys-account-withdraw-amount">0</div>
							<div class="ys-account-withdraw-amount-label">可提现奖金（元）</div>
						</div>
						<div class="ys-account-withdraw-container">
							<div class="ys-account-withdraw-amount">0</div>
							<div class="ys-account-withdraw-amount-label">已提现奖金（元）</div>
						</div>
					</div>
				</div>

				<div class="ys-account-my-prize-grid">
					<div class="ys-account-my-data-container">
						<img src="../assets/wdjj.png" alt="my prize" class="ys-account-my-data-img">
						<div class="ys-account-my-data-text-container">
							<div class="ys-account-my-data-label">我的奖金</div>
							<div class="ys-account-my-data-amount">0</div>
						</div>
					</div>
					<div class="ys-account-my-data-container">
						<img src="../assets/tgdd.png" alt="my prize" class="ys-account-my-data-img">
						<div class="ys-account-my-data-text-container">
							<div class="ys-account-my-data-label">推广订单</div>
							<div class="ys-account-my-data-amount">0</div>
						</div>
					</div>
					<div class="ys-account-my-data-container">
						<img src="../assets/txmx.png" alt="my prize" class="ys-account-my-data-img">
						<div class="ys-account-my-data-text-container">
							<div class="ys-account-my-data-label">提现明细</div>
							<div class="ys-account-my-data-amount">0</div>
						</div>
					</div>
					<div class="ys-account-my-data-container" @click="onMyFans">
						<img src="../assets/wdfs.png" alt="my prize" class="ys-account-my-data-img">
						<div class="ys-account-my-data-text-container">
							<div class="ys-account-my-data-label">我的粉丝</div>
							<div class="ys-account-my-data-amount">{{ this.getReferMemberCount }}</div>
						</div>
					</div>

				</div>
			</div>
		</div>




	</div>
</template>

<script>
import {getReferMember} from "@/utils/methods";
export default {
	name: "AccountCenterView",
	computed: {
		getShopName() {
			return this.$store.state.userInfo.shopName
		},
		getReferenceInfo () {
			if (!this.$store.state.userInfo.referByPhoneNum || !this.$store.state.userInfo.referByUserName) {
				return '无'
			}
			return `${this.$store.state.userInfo.referByPhoneNum} (${this.$store.state.userInfo.referByUserName})`
		},
		getReferMemberCount () {
			return this.$store.state.referMemberList.length
		}
	},
	async mounted() {
		const {err, result} = await getReferMember()
		if (err === false && result?.code === 200 && Array.isArray(result?.msg?.referMemberList)) {
			this.$store.dispatch('setReferMemberList', result?.msg?.referMemberList)
		}

	},
	methods: {
		onMyFans() {
			this.$router.push({ name: 'myFans'})
		},
		isShowRouterView () {

			this.$emit('updateTitle')
			return this.$route.name !== 'accountCenter'
		}
	}
}
</script>

<style scoped>
.ys-account-center-view-container {
	position: relative;
	display: flex;
	overflow-y: auto;
	flex-direction: column;
	overflow-x: hidden;
}

.ys-account-center-view-wrapper{
	position: relative;
	display: flex;
	flex-direction: column;
}

.ys-account-name-container {
	display: flex;
	flex-direction: column;
	background-color: transparent;
	align-items: start;
	padding: 2em 2em 0.5em 2em;
	border-bottom-left-radius: 3em; /* 左下角圆角 */
	border-bottom-right-radius: 3em; /* 右下角圆角 */
	z-index: 3;
}

.ys-account-name-container-bg {
	display: flex;
	flex-direction: column;
	background: rgb(231, 191, 146);
	background: linear-gradient(90deg, rgba(231, 191, 146, 1) 0%, rgba(245, 236, 203, 1) 100%);
	align-items: start;
	padding: 2em 4em 8em 4em;
	border-bottom-left-radius: 3em; /* 左下角圆角 */
	border-bottom-right-radius: 3em; /* 右下角圆角 */
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
}

.ys-account-name-label {
	color: #ffff;
	margin-bottom: 0.4em;
	font-weight: bold;
}

.ys-account-name-label-1 {
	display: inline-block;
	padding: 2px 7px;
	max-height: 100%;
	border-radius: 8px;
	background: #26252a;
	font-size: 14px;
	color: #ecdeb9;
	line-height: 16px;
}

.ys-account-my-price {
	background-color: #ffff;
	border-radius: 0.6em;
	display: flex;
	flex-direction: column;
	padding: 1em;
}

.ys-account-my-prize-grid {
	display: grid;
	grid-template-columns: 1fr 1fr; /* 创建两个等宽的列 */
	gap: 0.6em;
	margin-top: 0.6em;
}

.ys-account-my-price-row {
	display: flex;
	flex-direction: row;
	padding: 0.6em 0;
	border-bottom: 1px solid #e6e6e6;
	justify-content: center;
}

.ys-account-my-price-row-1 {
	display: flex;
	flex-direction: row;
	padding: 0.6em 0;
	justify-content: center;
}

.ys-account-data-container {
	display: flex;
	flex-direction: column;
	padding: 1em;
	z-index: 3;
}

.ys-account-withdraw-btn {
	text-align: end;
	color: #999;
	display: flex;
	flex-direction: row;
	cursor: pointer;
}

.ys-account-my-prize-label {
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	flex: 1;
}


.ys-account-my-prize-img {
	margin-right: 0.4em;
}

.ys-account-withdraw-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
}

.ys-account-withdraw-amount {
	color: #fea845;
	font-size: 2em;
	padding: 0.6em;
}

.ys-account-withdraw-amount-label {
	font-size: 0.8em;
}

.ys-account-my-data-container {
	display: flex;
	flex-direction: row;
	background-color: #fff;
	justify-content: center;
	align-items: center;
	padding: 1em;
	border-radius: 0.6em;
}

.ys-account-my-data-img {
	height: 2.2em;
	margin-right: 0.8em;
}

.ys-account-my-data-text-container {
	display: flex;
	flex-direction: column;
	align-items: start;
}

.ys-account-my-data-amount {
	color: #999;
	font-size: 0.9em;
}

.ys-account-my-data-label {
	font-size: 1em;
	margin-bottom: 0.2em;
}
</style>