<template>
	<div class="ys-product-manage-container">
		<a-modal v-model:open="openOnSellModal" :title="`指定商品 ${this.onSellProductCode}`">
			<template #footer>
				<a-button key="submit" @click="cancelOnSell">取消</a-button>
				<a-button key="back" type="primary" @click="confirmOnSell">确定</a-button>
			</template>
			<div>指定的会员：{{ this.selectedUser?.userName }}</div>
			<a-input class="ys-product-manage-input-on-sell-refer-code" v-model:value="selectMemberSearchWord"
			         size="large"
			         placeholder="搜索会员"/>
			<div class="ys-product-select-member-container">
				<div>请在下表中选择指定会员</div>
				<div class="ys-product-select-member-list">
					<div class="ys-product-select-member-card" v-for="item in this.allMembers" :key="item.userId"
					     @click="this.onMemberListItemClicked(item)">
						<div class="ys-product-select-member-card-text-container">
							<div>{{ item.userName }}</div>
							<div>推荐码：{{ item.referCode }}</div>
						</div>
						<img v-if="this.canShowMemberChecked(item)" src="../assets/check.svg" alt="check">
					</div>
				</div>
			</div>
		</a-modal>
		<a-modal v-model:open="openSplitProductModal" :title="`拆分商品 ${this.selectedProduct?.productCode}`">
			<template #footer>
				<a-button key="submit" @click="cancelSplitProduct">取消</a-button>
				<a-button key="back" type="primary" @click="confirmSplitProduct">确定</a-button>
			</template>
			<div>输入拆分金额 (1-{{ this.selectedProduct?.amount }})</div>
			<a-input class="ys-product-manage-input-on-sell-refer-code" v-model:value="splitProductAmount" size="large"
			         type="number"
			         placeholder="请输入拆分金额"/>
		</a-modal>
		<a-tabs v-model:activeKey="displayProductType">
			<a-tab-pane key="ActiveProducts" tab="正常商品">
				<div v-for="item in this.allActiveProducts" :key="item.productId"
				     class="ys-product-manage-container-row-wrapper">
					<div class="ys-product-manage-cell-header">{{ this.getProductHeaderDisplayText(item) }}</div>
					<div class="ys-product-manage-container-row">
						<div class="ys-product-manage-image-container">
							<img class="ys-product-manage-image" :src=item.imgUrl alt="商品图片">
						</div>
						<div class="ys-product-manage-text-container">
							<div class="ys-product-manage-text-cell">
								<div class="ys-product-manage-text-cell-content" style="font-weight: bold;">{{
										item.productCode
									}}
								</div>
							</div>
							<div class="ys-product-manage-text-cell">
								<div class="ys-product-manage-text-cell-content">持有人：{{ item.userName }}</div>
							</div>
							<div class="ys-product-manage-text-cell">
								<div class="ys-product-manage-text-cell-content">推荐码：{{ item.referCode }}</div>
								<div class="ys-product-manage-text-cell-copy-btn"
								     @click="copyUserReferCode(item.referCode)">
									复制
								</div>
							</div>
							<div class="ys-product-manage-text-cell">
								<div class="ys-product-manage-text-cell-content">
									价格：{{ item.amount }}
								</div>
							</div>
						</div>
					</div>
					<div class="ys-product-manage-container-row-action-container">
						<div v-if="!item.onSellInfo" class="ys-product-manage-container-row-action"
						     @click="onSellClick(item.productId, item.productCode)">指定
						</div>
						<div v-if="this.canShowCancelOnSell(item)" class="ys-product-manage-container-row-action"
						     @click="onCancelOnSell(item)">撤回指定
						</div>
						<div v-if="!item.onSellInfo" class="ys-product-manage-container-row-action" @click="onSplitProduct(item)">商品拆分</div>
						<div v-if="!item.onSellInfo" class="ys-product-manage-container-row-action"
						     @click="onCloseProduct(item.productCode, item.productId)">封存
						</div>
						<div v-if="!item.onSellInfo" class="ys-product-manage-container-row-action">转场</div>
					</div>
				</div>
			</a-tab-pane>
			<a-tab-pane key="ClosedProducts" tab="封存商品" force-render>

				<div v-for="item in this.allClosedProducts" :key="item.productId"
				     class="ys-product-manage-container-row-wrapper">
					<div class="ys-product-manage-container-row">
						<div class="ys-product-manage-image-container">
							<img class="ys-product-manage-image" :src=item.imgUrl alt="商品图片">
						</div>
						<div class="ys-product-manage-text-container">
							<div class="ys-product-manage-text-cell">
								<div class="ys-product-manage-text-cell-content" style="font-weight: bold;">{{
										item.productCode
									}}
								</div>
							</div>
							<div class="ys-product-manage-text-cell">
								<div class="ys-product-manage-text-cell-content">持有人：{{ item.userName }}</div>
							</div>
							<div class="ys-product-manage-text-cell">
								<div class="ys-product-manage-text-cell-content">推荐码：{{ item.referCode }}</div>
								<div class="ys-product-manage-text-cell-copy-btn"
								     @click="copyUserReferCode(item.referCode)">
									复制
								</div>
							</div>
							<div class="ys-product-manage-text-cell">
								<div class="ys-product-manage-text-cell-content">
									价格：{{ item.amount }}
								</div>
							</div>
						</div>
					</div>
					<div class="ys-product-manage-container-row-action-container">
						<div class="ys-product-manage-container-row-action"
						     @click="onActivateProduct(item.productCode, item.productId)">解除封存
						</div>
					</div>
				</div>
			</a-tab-pane>
		</a-tabs>

	</div>

</template>

<script>
import {
	getAllActiveProducts,
	closeProduct,
	getAllClosedProducts,
	activateProduct,
	onSellProduct,
	cancelOnSell,
	getAllMemberList,
	splitProduct
} from "@/utils/methods";
import copy from "copy-to-clipboard";
import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {createVNode} from 'vue';
import {Modal} from 'ant-design-vue';

export default {
	name: "ProductManageView",
	async mounted() {
		await this.fetchAllActiveProducts()

		this.allMemberList = await this.getALlMemberList()
	},
	data() {
		return {
			openCloseProductConfirm: false,
			openSplitProductModal: false,
			displayProductType: 'ActiveProducts',
			openOnSellModal: false,
			onSellProductId: null,
			onSellProductCode: null,
			onSellUserReferCode: null,
			allMemberList: [],
			selectedUser: null,
			selectMemberSearchWord: null,
			splitProductAmount: 1000,
			selectedProduct: null
		}
	},
	computed: {
		allActiveProducts() {
			return this.$store.state.activeProductList
		},
		allClosedProducts() {
			return this.$store.state.closedProductList
		},
		allMembers() {
			if (!this.selectMemberSearchWord) {
				return this.allMemberList
			} else {
				const toReturn = []
				for (const item of this.allMemberList) {
					if (item.userName?.indexOf(this.selectMemberSearchWord) > -1 || item.referCode?.indexOf(this.selectMemberSearchWord) > -1 || item.phoneNum?.indexOf(this.selectMemberSearchWord) > -1) {
						toReturn.push(item)
					}
				}
				return toReturn
			}
		}
	},
	methods: {
		canShowCancelOnSell (item) {
			if ((item.orderState === 'wait_for_payment' || item.orderState === 'wait_for_payment_confirm') && item.onSellInfo) {
				return true
			}
			return false
		},
		async fetchAllActiveProducts () {
			let {err, result} = await getAllActiveProducts()
			if (err === false && result?.code === 200) {
				this.$store.dispatch('setActiveProductList', result.msg)
			}
			({err, result} = await getAllClosedProducts())
			if (err === false && result?.code === 200) {
				this.$store.dispatch('setClosedProductList', result.msg)
			}
		},
		async getALlMemberList() {
			if (!Array.isArray(this.$store.state.allMemberList) || this.$store.state.allMemberList.length === 0) {
				const {err, result} = await getAllMemberList()
				if (err === false && result?.code === 200) {
					return result.msg
				}
				return []
			}
			return this.$store.state.allMemberList

		},
		onSellClick(productId, productCode) {
			this.onSellProductId = productId
			this.openOnSellModal = true
			this.onSellProductCode = productCode
		},
		copyUserReferCode(code) {
			const copyResult = copy(code);
			if (copyResult === true) {
				this.$message.success({
					content: '复制成功',
					duration: 3 // 设置持续时间为3秒
				});
			} else {

				this.$message.error({
					content: '复制失败',
					duration: 3 // 设置持续时间为3秒
				});
			}
		},
		onCloseProduct(productCode, productId) {
			Modal.confirm({
				content: `确认封存商品 ${productCode} ？`,
				icon: createVNode(ExclamationCircleOutlined),
				onOk: async function () {
					let {err, result} = await closeProduct(productId)
					if (err === false && result?.code === 200) {
						this.$message.success({
							content: '操作成功',
							duration: 3 // 设置持续时间为3秒
						});
						let {err, result} = await getAllClosedProducts()
						if (err === false && result?.code === 200) {
							this.$store.dispatch('setClosedProductList', result.msg)
						}
						({err, result} = await getAllActiveProducts())
						if (err === false && result?.code === 200) {
							this.$store.dispatch('setActiveProductList', result.msg)
						}
					} else {
						this.$message.error({
							content: '操作失败',
							duration: 3 // 设置持续时间为3秒
						});
					}
				}.bind(this),
				cancelText: '取消',
				okText: '确认',
				onCancel() {
					Modal.destroyAll();
				}
			});
		},
		onActivateProduct(productCode, productId) {
			Modal.confirm({
				content: `确认解封商品 ${productCode} ？`,
				icon: createVNode(ExclamationCircleOutlined),
				onOk: async function () {
					let {err, result} = await activateProduct(productId)
					if (err === false && result?.code === 200) {
						this.$message.success({
							content: '操作成功',
							duration: 3 // 设置持续时间为3秒
						});
						let {err, result} = await getAllClosedProducts()
						if (err === false && result?.code === 200) {
							this.$store.dispatch('setClosedProductList', result.msg)
						}
						({err, result} = await getAllActiveProducts())
						if (err === false && result?.code === 200) {
							this.$store.dispatch('setActiveProductList', result.msg)
						}
					} else {
						this.$message.error({
							content: '操作失败',
							duration: 3 // 设置持续时间为3秒
						});
					}
				}.bind(this),
				cancelText: '取消',
				okText: '确认',
				onCancel() {
					Modal.destroyAll();
				}
			});
		},
		getProductHeaderDisplayText(item) {
			if (item.onSellInfo) {
				return `已指定给：${item.onSellInfo?.userName}`
			}
			return '正常商品'
		},
		async confirmOnSell() {
			if (!this.selectedUser?.referCode) {
				this.$message.error({
					content: '请选择会员',
					duration: 3 // 设置持续时间为3秒
				});
				return
			}

			let {err, result} = await onSellProduct(this.onSellProductId, this.selectedUser?.referCode)
			if (err === true) {
				this.$message.error({
					content: '操作失败',
					duration: 3 // 设置持续时间为3秒
				});
				return
			}

			if (err === false) {
				if (result?.code === 200) {
					this.$message.success({
						content: '操作成功',
						duration: 3 // 设置持续时间为3秒
					});
					({err, result} = await getAllActiveProducts())
					if (err === false && result?.code === 200) {
						this.$store.dispatch('setActiveProductList', result.msg)
					}
					this.openOnSellModal = false
					this.onSellUserReferCode = null
					return
				}

				if (result?.code === 203) {
					this.$message.error({
						content: '推荐码不存在',
						duration: 3 // 设置持续时间为3秒
					});
					return
				}
				if (result?.code === 202) {
					this.$message.error({
						content: '登录过期',
						duration: 3 // 设置持续时间为3秒
					});
					return
				}
				if (result?.code === 201) {
					this.$message.error({
						content: '认证失败',
						duration: 3 // 设置持续时间为3秒
					});
				}
			}
		},
		cancelOnSell() {

			this.openOnSellModal = false
		},
		async onCancelOnSell(item) {

			Modal.confirm({
				content: `确认撤回指定商品 ${item.productCode} ？`,
				icon: createVNode(ExclamationCircleOutlined),
				onOk: async function () {
					let {err, result} = await cancelOnSell(item.productId)
					if (err === true) {
						this.$message.error({
							content: '操作失败',
							duration: 3 // 设置持续时间为3秒
						});
						return
					}
					if (err === false) {
						if (result?.code === 200) {
							this.$message.success({
								content: '操作成功',
								duration: 3 // 设置持续时间为3秒
							});
							({err, result} = await getAllActiveProducts())
							if (err === false && result?.code === 200) {
								this.$store.dispatch('setActiveProductList', result.msg)
							}
							this.openOnSellModal = false
							return
						}

						if (result?.code === 202) {
							this.$message.error({
								content: '登录过期',
								duration: 3 // 设置持续时间为3秒
							});
							return
						}
						if (result?.code === 201) {
							this.$message.error({
								content: '认证失败',
								duration: 3 // 设置持续时间为3秒
							});
						}
					}
				}.bind(this),
				cancelText: '取消',
				okText: '确认',
				onCancel() {
					Modal.destroyAll();
				}
			});
		},
		canShowMemberChecked(item) {
			if (item.userId === this.selectedUser?.userId) {
				return true
			}
			return false
		},
		onMemberListItemClicked(item) {
			this.selectedUser = item
		},
		onSplitProduct(item) {
			this.selectedProduct = item
			this.openSplitProductModal = true
		},
		cancelSplitProduct() {
			this.openSplitProductModal = false
		},
		async confirmSplitProduct() {
			if (this.splitProductAmount < 1 || this.splitProductAmount > parseFloat(this.selectedProduct?.amount)) {
				this.$message.error({
					content: '分拆价格超出范围',
					duration: 3 // 设置持续时间为3秒
				});
				return
			}
			const {err, result} = await splitProduct(this.selectedProduct?.productId, this.splitProductAmount)
			if (err === false && result?.code === 200) {
				this.$message.success({
					content: '拆分成功',
					duration: 3 // 设置持续时间为3秒
				});
				await this.fetchAllActiveProducts()
				this.openSplitProductModal = false
			}
		}
	}

}
</script>

<style scoped>
.ys-product-manage-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 0.4em 0.4em 5em 0.4em;
	box-sizing: border-box;

}

.ys-product-manage-container-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-bottom: 0.8em;
	background-color: #ffffff;
	box-sizing: border-box;
	align-items: center;
	border-bottom: 1px solid #e3e3e3;
}

.ys-product-manage-image-container {
	max-width: 8em;
	max-height: 8em;
	margin-right: 0.8em;
}

.ys-product-manage-text-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	flex: 1;
}

.ys-product-manage-image {
	width: 100%;
}

.ys-product-manage-text-cell {
	margin-bottom: 0.2em;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
}

.ys-product-manage-text-cell-copy-btn {
	color: #FF8400;
	border: 1px solid #FF8400;
	padding: 0 0.4em;
	font-size: 1em;
	border-radius: 0.2em;
}

.ys-product-manage-text-cell-content {
	flex: 1;
	text-align: start;
}

.ys-product-manage-container-row-wrapper {
	display: flex;
	flex-direction: column;
	align-items: start;
	width: 100%;
	margin-bottom: 0.8em;
	padding: 0.8em;
	border-radius: 0.4em;
	box-sizing: border-box;
	background-color: #ffffff;

	overflow-y: auto;
}

.ys-product-manage-container-row-action-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 创建两个等宽的列 */
	gap: 0.6em;
	margin-top: 0.6em;
	align-items: center;
	justify-content: center;
	padding: 0.4em;
	box-sizing: border-box;
}

.ys-product-manage-container-row-action {
	color: dodgerblue;
	padding: 0.2em;
	border: 1px solid dodgerblue;
	border-radius: 0.2em;

}

.ys-product-manage-input-on-sell-refer-code {
	margin: 1em 0;
}

.ys-product-manage-cell-header {
	width: 100%;
	text-align: end;
	color: dodgerblue;
}

.ys-product-select-member-container {
	width: 100%;
	display: flex;
	flex-direction: column;

}

.ys-product-select-member-list {
	width: 100%;
	display: flex;
	flex-direction: column;
	max-height: 20em;
	overflow-y: auto;
	border: 1px solid dodgerblue;
	padding: 0.4em;
	border-radius: 0.4em;
}

.ys-product-select-member-card {
	border-bottom: 1px solid #d7d7d7;
	padding: 0.4em 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.ys-product-select-member-card-text-container {
	flex: 1;
	display: flex;
	flex-direction: column;
}
</style>